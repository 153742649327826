import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../core/services/auth.service';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { UserService } from '../core/user/user.service';
import { Router } from '@angular/router';
import { RouteName } from '../core/enums/RouteName';
import { OrderService } from '../home/order/order.service';
import { ErrorCode } from '../core/enums/ErrorCode';
import { CustomService } from '../core/services/custom.service';
import { CustomRequest } from '../core/models/CustomRequest';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthService,
    public userService: UserService,
    private router: Router,
    private orderService: OrderService,
    private customService: CustomService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getAccessToken()) {
      request = this.addTokenRecallApi(request, this.authService.getAccessToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && error.error.errorCode !== ErrorCode.DeletedAccount_401) {
        return this.handle401Error(request, next);
      } else if (error instanceof HttpErrorResponse && error.status === 401 && error.error.errorCode === ErrorCode.DeletedAccount_401) {
        return EMPTY;
      } else {
        return throwError(error);
      }
    }));
  }

  private addTokenRecallApi(request: HttpRequest<any>, token: string, customRequest?: CustomRequest): HttpRequest<any> {
    let clonedRequest = request.clone();
    let xSignature = '';

    if (customRequest) {
      xSignature = this.customService.assignHMac(customRequest);
    }

    if (token) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
        }
      });
    }

    if (xSignature) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          'X-Signature': xSignature,
        }
      });
    }

    return clonedRequest;
  }

  private async failedRefreshTokenRecall(): Promise<any> {
    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];
    let user = this.userService.getCustomer();

    if (user && currentRoute != RouteName.Login && currentRoute != '' && currentRoute != RouteName.OdaringQr && currentRoute == RouteName.OrderPayment) {
      this.orderService.openSessionExpireMessage();
    } else {
      this.userService.logout();
      this.router.navigate(['home']);
    }

    return null;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let customRequest = this.customService.getRecallCustomRequest();

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      let authoriseData = {
        refreshResult: null,
        loginResult: null
      };

      return this.authService.refreshTokenApi().pipe(
        switchMap(async (refreshRespond) => {
          let refreshRespondData = await refreshRespond;
          this.isRefreshing = false;
          if (refreshRespondData instanceof HttpErrorResponse) {
            this.refreshTokenSubject.next(null);
            authoriseData.loginResult = await this.failedRefreshTokenRecall();
          } else {
            authoriseData.refreshResult = refreshRespondData;
          }
          return authoriseData;
        }),
        switchMap((authoriseData: any) => {
          let accessToken = null;
          if (authoriseData.loginResult) {
            accessToken = authoriseData.loginResult.accessToken;
          } else if (authoriseData.refreshResult) {
            accessToken = authoriseData.refreshResult.accessToken;
          }
          this.refreshTokenSubject.next(accessToken);
          return next.handle(this.addTokenRecallApi(request, accessToken, customRequest));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addTokenRecallApi(request, accessToken));
        }));
    }
  }
}
