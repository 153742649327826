import { AppLinkTokenResponse } from 'src/app/core/models/AppLinkTokenResponse';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { StoreMenuResponseExtra } from 'src/app/core/models/StoreMenuResponseExtra';
import { StoreItemStatsModel } from './../../../core/store-item-stats/store-item-stats.model';
import { StoreItemStatsService } from './../../../core/store-item-stats/store-item-stats.service';
import { QueueStatusFlag } from './../../../core/enums/QueueStatusFlag';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { CurrentStatusFlag } from 'src/app/core/enums/StatusFlag';
import { StoreStore } from '../../../store/store/store.store';
import { OrderTypeFlag } from '../../../core/enums/OrderTypeFlag';
import { OrderSourceFlag } from '../../../core/enums/OrderSourceFlag';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CartModel } from 'src/app/core/models/CartModel';
import { OrderH } from 'src/app/core/models/OrderH';
import * as _ from 'lodash';
import { OrderD } from 'src/app/core/models/OrderD';
import { CartService } from 'src/app/core/cart/cart.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { ChannelPlatformSetResponse } from 'src/app/core/models/ChannelPlatformSetResponse';
import { AvailableTime } from 'src/app/core/models/AvailableTime';
import { StoreService } from 'src/app/store/store/store.service';
import { CXMCustomerAddressResponse } from 'src/app/core/models/CXMCustomerAddressResponse';
import { UserService } from 'src/app/core/user/user.service';
import { MenuCatg } from 'src/app/core/models/MenuCatg';
import { MenuItem } from 'src/app/core/models/MenuItem';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { User } from 'src/app/core/user/user.model';
import { TimeService } from 'src/app/core/services/time.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Store } from 'src/app/store/store/store.model';
import { UserQuery } from 'src/app/core/user/user.query';
import { VoidFlag } from 'src/app/core/enums/VoidFlag';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { QrCartStore } from 'src/app/core/qr-cart/qr-cart.store';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { RewardService } from 'src/app/account/services/reward.service';
import { TimeInterval } from 'src/app/core/models/TimeInterval';
import { StoreOHAdvSchedule } from 'src/app/core/models/StoreOHAdvSchedule';
import { MenuSet } from 'src/app/core/models/MenuSet';
import { SetCode } from 'src/app/core/enums/SetCode';
import { MenuService } from 'src/app/core/menu/menu.service';
import { Menu } from 'src/app/core/menu/menu.model';
import { StoreItemStats } from 'src/app/core/models/StoreItemStats';
import * as deepClone from 'rfdc';
import { QueueResponse } from 'src/app/core/models/QueueResponse';
import { StaticQrState } from 'src/app/core/static-qr/static-qr.model';
import { LoaderService } from 'src/app/core/loader-services/loader.service';
import { MembershipWithPointStampResponse } from 'src/app/membership/membership/membership-with-point-stamp-response';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { MiniProgramService } from 'src/app/core/mini-program/mini-program.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-cart-form',
  templateUrl: './cart-form.component.html',
  styleUrls: ['./cart-form.component.scss']
})
export class CartFormComponent implements OnInit, OnDestroy {

  cartModel : CartModel;
  store : StoreMenuResponseExtra;
  orderH : OrderH;
  currentCrossSellList : MenuItem[] = [];
  storeItemStats : StoreItemStats[];
  staticData : StaticQrState;

  @Input() curStoreId: number;
  @Input() locDesc: string;
  @Input() isStorePage: boolean = false;
  @Input() storeStatus: string;
  @Input() qrTokenResponse: WebLinkTokenResponse;
  @Input() queueResponse : QueueResponse;
  @Input() appLinkTokenResponse : AppLinkTokenResponse;

  //membership
  merchantMemberships: MembershipWithPointStampResponse[];
  cardImageError: boolean = false;
  cutleryLabel: string = 'order.summary.title.5';

  //all of this code will be run when there is changes
  @Input() set storeData(value : StoreMenuResponseExtra){
    this.store = value;

    //if there is value passed in
    if(value){
      this.storeResponse = value.storeResponses;
      this.storeItemStats = value.storeItemStats;
      this.orderIntervalTime = this.storeResponse.platformSets.filter(val => val.setCode == "DELINTERVALMIN" || val.setCode == "PICKINTERVALMIN");
      this.changeImageSetting = this.storeResponse.platformSets.find(setting => setting.setCode == SetCode.DPPARENTIMG);
      let cutlerySet = this.storeResponse.platformSets.find(setting => setting.setCode == SetCode.CUTLERYLABEL);
      if (cutlerySet) {
        this.cutleryLabel = cutlerySet.setValue;
      }
      this.merchantMemberships = _.cloneDeep(value.MerchantMemberships);

      if(value.menuResponse){
        this.menuCatgs = deepClone({proto: true})(value.menuResponse[0].menuSets[0].menuCatgs);
        this.menuCatgsOriginal = deepClone({proto: true})(value.menuResponse[0].menuSets[0].menuCatgs);
        this.priceSymbol = value.menuResponse[0].currency.symbol;

        // process data to get cross sell item if there is any
        this.crossSellProcess(value.menuResponse[0].menuSets);
      }
    }
  }

  get storeData() : StoreMenuResponseExtra{
    return this.store;
  }

  @Input() set cartModelInput(value: CartModel) {
    this.cartModel = _.cloneDeep(value);
    this.onValueChange();
  }

  get cartModelInput(): CartModel {
    return this.cartModel;
  }

  @Input() set orderHInput(value: OrderH) {
    this.orderH = _.cloneDeep(value);
  }

  get orderHInput(): OrderH {
    return this.orderH;
  }

  @Input() set staticQrData(staticData : StaticQrState){
    if(staticData && this.orderH){
      if(staticData.tableNo && ((staticData?.storeId && staticData?.storeId == this.orderH.storeId) || !staticData?.storeId)){
        this.staticData = staticData;
      }
      else{
        this.staticData = null;
      }
    }
  }

  get staticQrData() : StaticQrState{
    return this.staticData;
  }

  @Output() openEditDialog = new EventEmitter();
  @Output() checkAttentionMessage = new EventEmitter<number>();
  @Output() checkStore = new EventEmitter();
  @Output() clickCrossSell = new EventEmitter();
  @Output() reachedMaximumQuantity = new EventEmitter();

  //cart dialog
  menuCatgs: MenuCatg[];
  menuCatgsOriginal: MenuCatg[];
  priceSymbol: string;

  //cutlery request (boolean)
  cutlery_request: boolean = false;

  channelId: number;
  channelTag: string;
  customerId: number | string;
  user: User;
  isLoggedIn: boolean = false;
  longitude: number;
  latitude: number;
  remark: string;

  //for floor unit and remarks
  tempUnitValue: string;
  tempRemarkValue: string;

  //subject type for input box
  unitNoModelChanged: Subject<string> = new Subject<string>();
  remarkModelChanged: Subject<string> = new Subject<string>();

  //for showing remove or cancel
  showRemoveDialog: boolean = false;

  //for address dialog
  displayAddress: boolean = false;
  savedAddress: CXMCustomerAddressResponse[] = [];
  updateAddressSuccess: boolean = false;

  //to get delivery time interval
  storeResponse: StoreResponse = null; //store response
  orderIntervalTime: ChannelPlatformSetResponse[] = []; // for storing interval
  orderIcon: string = "oda-delivery";

  //for time dialog
  showOrderTimeDialog: boolean = false;
  filteredTime: AvailableTime[] = [];
  curOrderDateSlot: AvailableTime[] = []; //to store current time and date for current order type
  currentOrderTime: AvailableTime = {} as AvailableTime; //to store current time object
  confirmedOrderTime: AvailableTime //for current and confirmed order time
  orderType: string;

  //for edit item
  orderLineGuid: string;
  displayStoreItm: boolean = false;
  isSubModifier: boolean = false;

  isMobileView: boolean;
  dialogPosition: string;
  dismissable: boolean;

  displayMobileRemoveDialog: boolean;
  currentOrderLineGuid: string;

  // store state object
  storeRespData: Store;
  isOrderType: boolean = false;

  displayOutOfCoverage: boolean = false;
  orderSourceFlag = OrderSourceFlag;
  statusFlag = CurrentStatusFlag;
  queueStatusFlag = QueueStatusFlag;

  //qr dine in
  displayDineInInfo: boolean;

  voidFlag = VoidFlag;

  //subscription
  sub1: Subscription;
  userSub: Subscription;
  qrExpiryDateSub$ : Subscription;

  //variation
  variationList: MenuItem[];
  parentMenuItem: MenuItem;
  changeImageSetting: ChannelPlatformSetResponse = null;

  storeMenuData : Menu;
  storeItemStatsData : StoreItemStatsModel;

  totalOrderInCartPerItem: { [key: string]: number } = {};
  maxQtyPerTran: { [key: string]: number } = {};
  minQtyPerTran: { [key: string]: number } = {};
  displayMaxPerTranError: boolean = false;
  displaymaxQtyPerTranText: number;

  isHidePromotion: boolean = false;

  displayQrExpiryDate: boolean = false;

  constructor(
    private cartService: CartService,
    private storeService: StoreService,
    private router: Router,
    private userService: UserService,
    private storeStore: StoreStore,
    private channelQuery: ChannelQuery,
    private timeService: TimeService,
    private breakpointObserver: BreakpointObserver,
    private userQuery: UserQuery,
    private qrCartService: QrCartService,
    private qrCartStore: QrCartStore,
    private rewardService: RewardService,
    private channelService: ChannelService,
    private analyticsService : AnalyticsService,
    private menuService : MenuService,
    private storeItemStatsService : StoreItemStatsService,
    private queueService : QueueService,
    private loaderService : LoaderService,
    private previousRouteService : PreviousRouteService,
    private miniProgramService : MiniProgramService,
    private themeService: ThemeService,
  ) {

    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.dialogPosition = "bottom";
        this.dismissable = true;
        this.isMobileView = true;
      }
      else {
        this.dialogPosition = "center";
        this.dismissable = false;
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit() {

    this.userSub = this.userQuery.selectFirst().subscribe(user => {
      if (user) {
        this.user = user;
        this.customerId = user.customerId;
        this.isLoggedIn = true;
      }
      else {
        this.user = null;
        this.customerId = "null";
        this.isLoggedIn = false;
      }
    })

    this.sub1 = this.storeService.openCustomize$.subscribe(openCustomizePopup =>{
      this.isSubModifier = openCustomizePopup ? true : false;
    })

    this.channelQuery.selectFirst().subscribe(async (channel) => {
      if (channel) {
        this.channelId = channel.channelId;
        this.channelTag = channel.channelTag;
      }
    });

    this.userQuery.selectedAddress$.subscribe(address => {
      this.longitude = address ? address.longitude : 0;
      this.latitude = address ? address.latitude : 0;
      this.remark = address ? address.remarks : null;
    })

    this.getQrExpiryDate();

    if (this.orderH) {
      this.cutlery_request = _.clone(this.orderH.orderData.cutleryFlag); //put a clone just in case there is any referencing

      if (this.orderH.orderData.orderC.reqTime != null && !this.qrTokenResponse && !this.appLinkTokenResponse) {
        this.orderH.orderData.orderC.reqTime = await this.cartService.checkIfCartTimePast(this.orderH.orderData.orderC.reqTime);

        let cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

        if (cartIndex != -1) {
          this.cartModel.orderHs[cartIndex].orderData.orderC.reqTime = this.orderH.orderData.orderC.reqTime;
        }
      }
    }

    await this.checkMenuCatgs();

    this.isHidePromotion = this.themeService.hidePromotion(this.channelTag);
  }

  async onValueChange() {
    await this.checkMenuCatgs();
  }

  async checkMenuCatgs() {
    if(this.isStorePage){
      if(this.menuCatgs && this.storeItemStats) {
        this.menuCatgs = await this.storeService.storeItemStatsFilter(this.storeItemStats, this.menuCatgs)
      }
    }

    let cartItem :  any[] = [];
    if(this.orderH){
      cartItem = await this.cartService.getTotalOrderItemIncart(this.orderH);
    }

    let currentItemList = this.orderH?.orderData?.orderDs ?? null;
    if (currentItemList && currentItemList.length != 0) {
      currentItemList.forEach(item => {
        const matched = cartItem.find(val => val.itemCode === item.itemCode);
        if(matched) {
          this.totalOrderInCartPerItem[item.itemCode] = matched.orderQty;
        }

        if (this.menuCatgs) {
          for (let menuCatgs of this.menuCatgs) {
            const matchedItem = menuCatgs.menuItems.find(val => val.itemCode === item.itemCode);
            if (matchedItem) {
              this.minQtyPerTran[item.itemCode] = matchedItem.minQtyPerTran ?? 0;
              this.maxQtyPerTran[item.itemCode] = matchedItem.maxQtyPerTran ?? 0;
            }
          }
        }
      })
    }
  }

  ngOnDestroy() {
    this.sub1?.unsubscribe();
    this.qrExpiryDateSub$?.unsubscribe();
    this.storeResponse = null;
  }

  async getStoreData(checkStore : boolean = true) {
    if(this.store && this.storeResponse && checkStore){
      return;
    }

    let orderType = this.storeService.getOrderType(this.orderH.orderData.sourceFlag);

    let storeResp = await this.storeService.getStoreMenuData(this.curStoreId, orderType, this.channelId, this.orderH.orderData.orderC.reqTime,
      this.channelTag, this.orderH.orderData.orderC.longitude, this.orderH.orderData.orderC.latitude, true, this.qrTokenResponse ? this.qrTokenResponse.linkId : null);

    return storeResp;
  }

  async onSelectStore(){
    if(this.orderH.orderData.itemQty == 0 || this.qrTokenResponse){
      return;
    }

    try{
      this.loaderService.startManualLoad();

      let channelData = this.channelService.getChannelData();
      let setting : ChannelPlatformSetResponse;
      let isShareTable : boolean = false;

      // if channel data exists then try to find the settings SHARETABLE
      if(channelData && channelData?.platformSets){
        setting = channelData.platformSets.find(settings => settings.setCode == SetCode.SHARETABLE);
        isShareTable = setting && setting?.setValue == "1" ? true : false;
      }

      // selected cart have table number
      let tableNoExists = this.cartModel.orderHs.some(orderH => orderH.isSelect && orderH.orderData.tableNo);

      // if current orderH isSelect is false then will call getStoreMenuData API
      // cause it is going to be select
      let storeResp : any;
      if(!this.orderH.isSelect){
        storeResp = await this.getStoreData(false);
      }

      if(this.cartModel.orderHs.length > 1){
        let cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

        // if selected cart have table number or selected cart dont have table number but current chosen have
        // then remove all selected
        if((tableNoExists && !isShareTable) ||
          (tableNoExists && !this.orderH.orderData.tableNo) ||
          (!tableNoExists && this.orderH.orderData.tableNo)){
          this.cartModel = await this.unselectAllCart(this.cartModel);
        }

        if(cartIndex != -1){
          this.cartModel.orderHs[cartIndex].isSelect = !this.orderH.isSelect;

          // update membership details when cart is selected
          if(this.cartModel.orderHs[cartIndex].isSelect){
            this.cartModel.orderHs[cartIndex] = await this.cartService.updateMembershipInit(this.cartModel.orderHs[cartIndex],
              storeResp.MerchantMemberships, this.appLinkTokenResponse);
          }

          await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, true);

          this.checkAttentionMessage.emit(this.orderH.storeId);
        }
      }

      this.loaderService.stopManualLoad();
    }catch(error){
      this.loaderService.stopManualLoad();
    }
  }

  async unselectAllCart(cartModel : CartModel){
    let orderHLength = cartModel.orderHs.length;

    for(let index = 0; index < orderHLength; index++){
      cartModel.orderHs[index].isSelect = false;
    }

    return cartModel;
  }

  async onClickOrderMore() {
    let orderType = this.storeService.getOrderType(this.orderH.orderData.sourceFlag);

    this.storeService.setCurrentOrderType(orderType);
    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.locDesc);
    this.router.navigate(['store', this.curStoreId, locDescWithoutSpaces]);
  }

  //when click input box then it will temporarily save the value
  onClickInputBox(value: string, isUnitNo: boolean = false) {

    if (isUnitNo) {
      this.tempUnitValue = value;
    }
    else {
      this.tempRemarkValue = value;
    }

  }

  //when input focus is out then it will update unit no
  updateUnitNo(e: any) {

    if (e.target.value != this.tempUnitValue) {
      this.orderH.orderData.orderC.addUnit = e.target.value;

      let cartIndex: number;
      cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

      this.cartModel.orderHs[cartIndex].orderData.orderC.addUnit = this.orderH.orderData.orderC.addUnit;

      this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
    }
  }

  //when input focus is out then it will update remarks value
  updateRemarkValue(e: any) {
    if (e.target.value != this.tempRemarkValue) {
      this.orderH.orderData.orderC.remarks = e.target.value;

      let cartIndex: number;
      cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

      this.cartModel.orderHs[cartIndex].orderData.orderC.remarks = this.orderH.orderData.orderC.remarks;

      this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
    }
  }

  //method is like angular Date pipe but it is custom using momentjs - use to get correct time string format
  getCorrectDisplayFormat(curTime: string) {
    let correctFormat;
    let localDate = this.timeService.getAdjustedLocalTimeInUtc();
    localDate = this.timeService.convertToTimezoned(localDate);

    if (curTime != null) {
      correctFormat = this.timeService.convertToTimezoned(moment(curTime));

      if (correctFormat.format("YYYY-MM-DD") == localDate.format("YYYY-MM-DD")) {
        return correctFormat.format("D/M");
      }
      else {
        return correctFormat.format("ddd D/M");
      }
    }
    else {
      correctFormat = this.timeService.getAdjustedLocalTimeInUtc();
      correctFormat = this.timeService.convertToTimezoned(correctFormat);

      return correctFormat.format("D/M");
    }
  }

  //use to check if date string is today or not - for the "Today" translation
  checkIfItIsToday(curTime: string) {
    let localTime = this.timeService.getAdjustedLocalTimeInUtc();
    localTime = this.timeService.convertToTimezoned(localTime);

    if (curTime == null) {
      return true;
    }
    else {
      let curTimeMoment = moment(curTime);
      if (curTimeMoment.format("YYYY-MM-DD") == localTime.format("YYYY-MM-DD")) {
        return true
      }
      else {
        return false;
      }
    }
  }

  async onClickEditAddress() {
    if (this.isLoggedIn) {
      this.savedAddress = await this.userService.getCustomerAddress(this.channelService.getChannelId());
    }
    this.displayAddress = true;
  }

  onClickViewMap() {
    let googleMapLink: string = "https://www.google.com/maps/search/?api=1&query=" + this.storeResponse.latitude + ',' + this.storeResponse.longitude
    window.open(googleMapLink);
  }

  async onChangeAddress(cartModel: CartModel) {
    await this.cartService.recalculateCart(this.channelId, this.customerId, cartModel, true);
    this.displayAddress = false;
  }

  closeDialog() {
    this.displayAddress = false;
  }

  async onClickScheduleInfo(){
    if(this.queueResponse || this.appLinkTokenResponse){
      return;
    }

    if(this.qrTokenResponse){
      this.openQrDineInInfo();
    }
    else if(this.orderH.orderData.tableNo){
      await this.openStaticPopup();
    }
    else{
      this.onClickEditTime();
    }
  }

  //#region advance time scheduling
  //method when the edit icon is clicked
  async onClickEditTime() {
    if (this.orderH?.orderData?.itemQty == 0) {
      return;
    }

    // check if store response existed, if not then call getStoreMenuData API
    let storeResp = await this.getStoreData();
    // if no store data then use the data return by API first else use data that is passed in
    this.storeResponse = storeResp ? storeResp.storeResponses : this.storeResponse;

    this.confirmedOrderTime = await this.cartService.getConfirmedOrderTime(this.orderH, this.orderIntervalTime, this.orderH.orderData.orderC.reqTime, this.storeResponse.storeOHSchedules);

    let dateMoment = moment(this.confirmedOrderTime.date);
    dateMoment = this.timeService.convertToTimezoned(dateMoment);

    this.filteredTime = [];

    let isPreorder: boolean;
    let processedSchedule: StoreOHAdvSchedule;
    if (this.orderH && this.orderH?.orderData.orderC.preorderTime) {
      isPreorder = true;
      processedSchedule = await this.storeService.filterLeadTimeSchedule(this.storeResponse.storeOHSchedules, this.orderH.orderData.orderC.preorderTime);
    }
    else {
      isPreorder = false;
      processedSchedule = null;
    }

    this.filteredTime = await this.storeService.filterTime(processedSchedule ? processedSchedule : this.storeResponse.storeOHSchedules, this.orderIntervalTime,
      this.storeResponse, isPreorder);

    if (this.orderH.orderData.sourceFlag != OrderSourceFlag.WebDineIn) {
      this.filteredTime.sort((val1, val2) => { return <any>new Date(val1.date) - <any>new Date(val2.date) });

      this.curOrderDateSlot = this.filteredTime.filter(val => val.orderType == this.confirmedOrderTime.orderType);

      let curIndex = this.filteredTime.findIndex(val => val.date == dateMoment.format('YYYY-MM-DD') && val.orderType == this.confirmedOrderTime.orderType);

      if (curIndex != -1) {
        this.filteredTime.forEach(val => {
          if (val.date == dateMoment.format('YYYY-MM-DD') && val.orderType == this.confirmedOrderTime.orderType) {
            this.currentOrderTime.timeInterval = _.cloneDeep(val.timeInterval);
            this.currentOrderTime.minTime = val.minTime;
            this.currentOrderTime.interval = val.interval;
            val.isAsap = this.confirmedOrderTime.isAsap;
            val.chosenTime = this.confirmedOrderTime.chosenTime;
          }
        });

        this.currentOrderTime.orderType = this.confirmedOrderTime.orderType;
        this.currentOrderTime.date = this.confirmedOrderTime.date;
        this.currentOrderTime.isToday = this.confirmedOrderTime.isToday;
        this.currentOrderTime.isAsap = this.confirmedOrderTime.isAsap;
        this.currentOrderTime.chosenTime = this.confirmedOrderTime.chosenTime;
      }
      else {
        this.currentOrderTime.timeInterval = _.cloneDeep(this.curOrderDateSlot[0].timeInterval);
        this.currentOrderTime.minTime = this.curOrderDateSlot[0].minTime;
        this.currentOrderTime.interval = this.curOrderDateSlot[0].interval;
        this.currentOrderTime.orderType = this.curOrderDateSlot[0].orderType;
        this.currentOrderTime.date = this.curOrderDateSlot[0].date;
        this.currentOrderTime.isToday = false;
        this.currentOrderTime.isAsap = false;
        this.currentOrderTime.chosenTime = this.curOrderDateSlot[0].timeInterval[0];

        this.filteredTime.map(val => {
          if (val.date == this.currentOrderTime.date && val.orderType == this.currentOrderTime.orderType) {
            val.isAsap = this.currentOrderTime.isAsap;
            val.chosenTime = this.currentOrderTime.chosenTime;
          }
        })
      }
    }
    else if (this.orderH.orderData.sourceFlag == OrderSourceFlag.WebDineIn) {
      this.currentOrderTime.orderType = OrderTypeFlag.DineIn;
      this.currentOrderTime.timeInterval = [];
      this.currentOrderTime.chosenTime = {} as TimeInterval;
      this.currentOrderTime.chosenTime.label = "ASAP";
      this.currentOrderTime.chosenTime.value = null;
    }

    this.showOrderTimeDialog = true;
  }

  closeOrderTimeDialog(e) {
    this.showOrderTimeDialog = false;
  }

  async checkoutOfTime(data) {
    this.showOrderTimeDialog = false;
    this.displayDineInInfo = false;

    let respDt = await this.storeService.getStoreMenuData(this.orderH.storeId, data.chosenSchedule.orderType, this.channelId,
      data.chosenSchedule.chosenTime.value, this.channelTag, this.orderH.orderData.orderC.longitude, this.orderH.orderData.orderC.latitude, false);

    if (respDt.StoreResponse.currentStatus == CurrentStatusFlag.OutofCoverage) {
      this.displayOutOfCoverage = true;
    }
    else{
      if(this.orderH.orderData.menuRowVersion != respDt.StoreResponse.menuRowVersion){
        this.initStateData(this.orderH.storeId, respDt);

        this.showRemoveDialog = true;
        this.isOrderType = true;
      }
      else {
        this.orderH = await this.cartService.updateOrderHTime(this.orderH, data.chosenSchedule, this.remark);
        this.orderH = await this.cartService.updateTableNoWithCondition(this.orderH, this.orderH.orderData.tableNo,
          this.staticData && this.staticData.guestCount ? this.staticData.guestCount : 0);
        this.orderH = await this.cartService.updateMembershipInit(this.orderH, respDt.MerchantMemberships);
        let cartIndex : number;
        cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);
        this.cartModel.orderHs[cartIndex] = this.orderH;
        this.cartModel.orderHs[cartIndex].orderData.orderC.refNo = this.qrCartService.getRefValue();
        await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, true);

        // clone the chosen time for data mutation purpose
        let timeModel = _.cloneDeep(data.chosenSchedule);
        // this method will update respective store related state
        this.storeService.timeChangeUpdateState(this.orderH.storeId, timeModel, respDt);
      }
    }
  }
  //#endregion

  //#region qr dine in
  // open qr dine in selection dialog
  openQrDineInInfo() {
    this.displayDineInInfo = true;
  }

  closeDineInInfoDialog() {
    this.displayDineInInfo = false;
  }

  async confirmDineInOrderType(orderType: string) {
    this.displayDineInInfo = false;

    let sourceFlag = this.qrCartService.getQrCartSourceFlag(orderType);

    let respDt = await this.storeService.getQrStoreMenuData(this.orderH.storeId, this.channelId, this.channelTag, orderType, this.qrTokenResponse.linkId, false);

    if(this.orderH.orderData.menuRowVersion != respDt.StoreResponse.menuRowVersion){
      this.initStateData(this.orderH.storeId, respDt);

      this.showRemoveDialog = true;
      this.isOrderType = true;
    }
    else {
      this.orderH.orderData.sourceFlag = sourceFlag;
      this.orderH.orderData.guestCount = this.qrTokenResponse.guestCount;
      let currentRefValue = this.qrCartService.getRefValue();
      this.orderH.orderData.orderC.refNo = currentRefValue ? currentRefValue : null;
      this.orderH = await this.qrCartService.updateOrderD(this.orderH);
      let cartIndex: number;
      cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);
      this.cartModel.orderHs[cartIndex] = this.orderH;
      this.cartModel = await this.cartService.updateUserInfo(this.cartModel, this.qrTokenResponse);
      await this.qrCartService.recalculateCart(this.channelId, this.qrTokenResponse.linkId, this.cartModel, true);

      // get current store menu row version before new store data is update to state
      let currentMenuRowVersion = this.storeResponse.menuRowVersion;
      if(currentMenuRowVersion != respDt.StoreResponse.menuRowVersion){
        this.storeService.upsertMenuState(this.orderH.storeId, respDt.storeMenuData);
      }

      this.storeService.upsertStoreState(this.orderH.storeId, respDt);
      this.storeItemStatsService.upsertStoreItemStats(this.orderH.storeId, respDt.StoreItemStats);
    }

  }
  //#endregion

  async openStaticPopup(){
    // check if store response existed, if not then call getStoreMenuData API
    let storeResp = await this.getStoreData();
    this.displayDineInInfo = true;
  }

  // close out of coverage dialog
  closeOutOfCoverageDialog() {
    this.displayOutOfCoverage = false;
  }

  async onClickEditItem(item : OrderD){
    if(this.queueResponse && this.queueResponse?.statusFlag == this.queueStatusFlag.Called){
      return;
    }

    if(item.voidFlag == VoidFlag.Available){
      // check if store response existed, if not then call getStoreMenuData API
      let storeResp = await this.getStoreData();
      // if no store data then use the data return by API first else use data that is passed in
      this.menuCatgs = storeResp ? deepClone({proto: true})(storeResp.menuResponse[0].menuSets[0].menuCatgs) : this.menuCatgs;

      item.orderQty = item.orderQty == 0 ? 1 : item.orderQty;
      let orderD = deepClone({proto: true})(item);

      if (orderD.parentItemCode && !this.isStorePage) {
        this.parentMenuItem = await this.storeService.searchByItemIdOrItemCode(orderD.parentItemCode, orderD.parentItemId, this.menuCatgs);
      }
      else {
        this.parentMenuItem = null;
      }

      this.variationList = await this.cartService.generateVariationList(orderD, this.menuCatgs);
      if (this.variationList.length > 0) {
        this.variationList = await this.storeService.variationImageCheck(this.variationList);
      }

      let mappedData: MenuItem;
      mappedData = await this.storeService.editItem(orderD, this.menuCatgs);

      this.orderLineGuid = item.orderLineGUID;

      // pass current mapped menu item into customize popup component
      this.menuService.selectedMenuItem$.next(mappedData);

      if (!this.isStorePage) {
        this.displayStoreItm = true;
      }
      else {
        this.openEditDialog.emit({ orderLineGuid: this.orderLineGuid, orderD: item });
      }
    }
  }

  onCloseEditDialog(event) {
    this.displayStoreItm = false;

    if(event.isIdle){
      this.timeService.isIdleRouting(this.qrTokenResponse, this.queueResponse, this.staticQrData);
    }
  }

  async onClickCrossSellItem(item: MenuItem) {
    this.orderLineGuid = null;
    this.analyticsService.viewCrossSellItemsEvent(item, this.orderH.orderData.currCode, this.orderH.orderData.menuSetId, this.orderH.storeId);

    // generate menu variation menu item lists if it exists
    if(item.variations && item.variations?.length > 0){
      this.parentMenuItem = deepClone({proto: true})(item);
      this.variationList = await this.cartService.findCrossSellMenuItem(this.menuCatgs, item.variations);
      if(this.variationList.length > 0){
        this.variationList = await this.storeService.variationImageCheck(this.variationList);
      }
    }
    else {
      this.parentMenuItem = null;
      this.variationList = [];
    }

    // pass current mapped menu item into customize popup component
    this.menuService.selectedMenuItem$.next(item);

    if (!this.isStorePage) {
      this.displayStoreItm = true;
    }
    else {
      this.clickCrossSell.emit();
    }
  }

  async minus_quantity(currentItem: OrderD, itemIndex: number) {
    if (currentItem.voidFlag == VoidFlag.Available) {

      if(currentItem.balanceQty && currentItem.balanceQty < 0 && !this.minQtyPerTran[currentItem.itemCode]) {
        let minusOderQty = Math.abs(currentItem.balanceQty);
        currentItem.orderQty -= minusOderQty;

      }else if(!currentItem.balanceQty && this.minQtyPerTran[currentItem.itemCode]){
        if(currentItem.orderQty == this.minQtyPerTran[currentItem.itemCode]){
          currentItem.orderQty -= this.minQtyPerTran[currentItem.itemCode];
        }else {
          currentItem.orderQty -= 1;
        }
      }else if(currentItem.balanceQty && this.minQtyPerTran[currentItem.itemCode]) {
        if(currentItem.balanceQty > this.minQtyPerTran[currentItem.itemCode] && currentItem.orderQty == this.minQtyPerTran[currentItem.itemCode]) {
          currentItem.orderQty -= this.minQtyPerTran[currentItem.itemCode];
        }else if(currentItem.balanceQty > this.minQtyPerTran[currentItem.itemCode] && currentItem.orderQty != this.minQtyPerTran[currentItem.itemCode]){
          currentItem.orderQty -= 1;
        }
        else if(currentItem.balanceQty < this.minQtyPerTran[currentItem.itemCode]){
          if(currentItem.balanceQty < 0) {
            let minusOderQty = Math.abs(currentItem.balanceQty);
            currentItem.orderQty -= minusOderQty
          }else if(currentItem.balanceQty > 0 && currentItem.orderQty == this.minQtyPerTran[currentItem.itemCode]){
            currentItem.orderQty -= this.minQtyPerTran[currentItem.itemCode];
          }
        }
      }else {
        currentItem.orderQty -= 1;
      }

      if (currentItem.orderQty == 0) {
        return;
      }

      let cartIndex: number;
      cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

      this.cartModel.orderHs[cartIndex].orderData.orderDs[itemIndex] = _.cloneDeep(currentItem);

      await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
    }
  }

  async plus_quantity(currentItem: OrderD, itemIndex: number) {
    if (currentItem.voidFlag == VoidFlag.Available) {

      if(currentItem.balanceQty && !this.maxQtyPerTran[currentItem.itemCode]){
        if( currentItem.balanceQty == 0){
          return;
        }
      }else if(!currentItem.balanceQty && this.maxQtyPerTran[currentItem.itemCode]) {
        if(this.totalOrderInCartPerItem[currentItem.itemCode] >= this.maxQtyPerTran[currentItem.itemCode]){
          this.displayMaxPerTranError = true;
          this.displaymaxQtyPerTranText = this.maxQtyPerTran[currentItem.itemCode];
               setTimeout(() => {
              this.displayMaxPerTranError = false;
            }, 5000);
          return;
        }
      }else if(currentItem.balanceQty && this.maxQtyPerTran[currentItem.itemCode]){
        if(currentItem.balanceQty > this.maxQtyPerTran[currentItem.itemCode] || currentItem.balanceQty == this.maxQtyPerTran[currentItem.itemCode]){
          if(this.totalOrderInCartPerItem[currentItem.itemCode] >= this.maxQtyPerTran[currentItem.itemCode]) {
            this.displayMaxPerTranError = true;
            this.displaymaxQtyPerTranText = this.maxQtyPerTran[currentItem.itemCode];
                 setTimeout(() => {
                this.displayMaxPerTranError = false;
              }, 5000);
            return;
          }
        }else if(currentItem.balanceQty < this.maxQtyPerTran[currentItem.itemCode]){
          if(currentItem.balanceQty == 0) {
            return;
          }
        }
      }

      currentItem.orderQty += 1;

      let cartIndex: number;
      cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

      this.cartModel.orderHs[cartIndex].orderData.orderDs[itemIndex] = _.cloneDeep(currentItem);

      await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
    }
  }

  cancelRemove(currentItem : OrderD){
   if(this.minQtyPerTran[currentItem.itemCode]){
    currentItem.orderQty = this.minQtyPerTran[currentItem.itemCode]
   }else {
    currentItem.orderQty = 1;
   }
  }

  async removeSingleItem(currentItem : OrderD){
    this.analyticsService.removeFromCart(currentItem, this.orderH);
    await this.cartService.removeSelectedItem(currentItem.orderLineGUID, this.qrTokenResponse, this.cartModel,
      this.orderH.storeId, this.queueResponse, this.appLinkTokenResponse);

      let storeIndex = this.cartModel.orderHs.findIndex(val => val.storeId == this.orderH.storeId);
      if(!this.cartModel.orderHs[storeIndex]){
        let fromStorePageData = this.cartService.getFromStoreFlag();

        if(fromStorePageData && fromStorePageData?.storeId){
        let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(fromStorePageData.storeTag);
        this.router.navigate(['store', fromStorePageData.storeId, locDescWithoutSpaces], { replaceUrl: true });
        this.cartService.removeIsFromStoreFlag();
      }
    }
  }

  displayMobileRemoveItemDialog(orderLineGuid: string) {
    this.displayMobileRemoveDialog = true;
    this.currentOrderLineGuid = orderLineGuid;
  }

  async removeSelectedItem(orderLineGuid: string) {
    this.displayMobileRemoveDialog = false;
    this.displayStoreItm = false;

    let currentItem = this.orderH.orderData.orderDs.find(item => item.orderLineGUID == orderLineGuid);
    if(currentItem){
      this.analyticsService.removeFromCart(currentItem, this.orderH);
      await this.cartService.removeSelectedItem(orderLineGuid, this.qrTokenResponse, this.cartModel, this.orderH.storeId,
        this.queueResponse, this.appLinkTokenResponse);
    }
  }

  closeMobileRemoveDialog() {
    this.displayMobileRemoveDialog = false;
  }

  onClickCutlery() {
    this.cutlery_request = !this.cutlery_request;
    this.updateCutleryValue(this.cutlery_request);
  }

  onChangeCutlerySwitch(event: any) {
    this.cutlery_request = event.checked;
    this.updateCutleryValue(this.cutlery_request);
  }

  updateCutleryValue(value: boolean) {
    this.orderH.orderData.cutleryFlag = value;

    let cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);
    this.cartModel.orderHs[cartIndex] = this.orderH;

    if(this.qrTokenResponse){
      this.qrCartStore.update(this.qrTokenResponse.linkId, entity => {
        return {
          cartModel: this.cartModel
        }
      })
    }
    else if(this.appLinkTokenResponse){
      this.miniProgramService.updateCart(this.appLinkTokenResponse.linkId, this.cartModel);
    }
    else{
      this.cartService.updateCart(this.cartModel);
    }
  }

  async onClickOrderNow(){
    // check if user is idle for more than a certain amount of time
    let isIdle = await this.timeService.isIdleCheck(new Date());

    // if is idle then will do respective routing based on which mode users are in
    if(isIdle){
      this.timeService.isIdleRouting(this.qrTokenResponse, this.queueResponse, this.staticQrData);
      return;
    }

    if(!this.queueResponse){
      let currentRoute = this.router.url;
      this.previousRouteService.saveCartBackUrl(currentRoute);
    }

    if(this.qrTokenResponse || this.queueResponse) {
      this.router.navigate(["cart"]);
    }
    else{
      if(this.storeStatus == CurrentStatusFlag.Open && this.orderH){
        this.cartService.saveIsFromStoreFlag(this.curStoreId, this.storeResponse.locDesc);
        this.orderH.isSelect = true;
        this.cartModel = await this.cartService.setOrderIsSelectToTrue(this.cartModel, this.orderH);
        this.cartModel = await this.cartService.shiftOrderIsSelectTrue(this.cartModel, this.orderH);
        await this.cartService.updateCart(this.cartModel);
        this.router.navigate(["cart"], { state: { storeId: this.curStoreId } });
      }
    }
  }

  onClickTrashCan() {
    if (this.isStorePage) {
      this.cartService.openRemoveOrderHDialog();
    }
    else if(this.queueResponse && this.queueResponse.statusFlag == this.queueStatusFlag.Called){
      this.queueService.showRemoveQueueCart();
    }
    else{
      this.isOrderType = false;
      this.showRemoveDialog = true;
    }
  }

  //might move it to another component
  onClickNo() {
    this.showRemoveDialog = false;
  }

  async removeAllItem(){
    let orderHIndex : number = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);
    let cartTranIndex = this.cartModel.cartTranIds.findIndex(val => val == this.cartModel.orderHs[orderHIndex].cartTranId);

    // run remove item event before splicing orderH
    this.analyticsService.removeCartStore(this.orderH);

    if(cartTranIndex != -1){
      this.cartModel.cartTranIds.splice(cartTranIndex, 1);
    }
    this.cartModel.orderHs.splice(orderHIndex, 1);

    let resp : any;
    resp = await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);

    //output to check the store exists for when there is only one orderH left
    this.checkStore.emit();
    this.checkAttentionMessage.emit();
    this.showRemoveDialog = false;

    if (!(resp instanceof HttpErrorResponse)){
      let fromStorePageData = this.cartService.getFromStoreFlag();

      if(fromStorePageData && fromStorePageData?.storeId){
        let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(fromStorePageData.storeTag);
        this.router.navigate(['store', fromStorePageData.storeId, locDescWithoutSpaces], { replaceUrl: true });
        this.cartService.removeIsFromStoreFlag();
      }
    }

  }

  closeRemoveAllDialog() {
    this.showRemoveDialog = false;
  }

  async orderTypeRemoveCart() {
    this.showRemoveDialog = false;

    if(this.storeRespData){
      // get current store response menu row version before new one is updated to the state
      let currentMenuRowVersion = this.storeResponse.menuRowVersion;
      // do checking to determine whether to update menu before new store response is update to state
      if(currentMenuRowVersion != this.storeRespData.storeResponse.menuRowVersion){
        this.menuService.upsertMenu(this.orderH.storeId, this.storeMenuData);
      }

      this.storeStore.upsert(this.orderH.storeId, this.storeRespData);
      this.storeItemStatsService.upsertStoreItemStats(this.orderH.storeId, this.storeItemStatsData);

      // clear all temporarily stored data after using it
      this.storeRespData = null;
      this.storeMenuData = null;
      this.storeItemStatsData = null;
    }

    // run remove item event before orderH is splice
    this.analyticsService.removeCartStore(this.orderH);

    let index = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH.cartGUID);

    let storeId: number;
    let locDesc: string;
    this.cartModel.orderHs.splice(index, 1);

    if(this.qrTokenResponse){
      storeId = this.qrTokenResponse.storeId;
      locDesc = this.qrTokenResponse.locDesc;
      await this.qrCartService.recalculateCart(this.channelId, this.qrTokenResponse.linkId, this.cartModel, true);
    }
    else if(this.appLinkTokenResponse){
      storeId = this.appLinkTokenResponse.storeId;
      locDesc = this.appLinkTokenResponse.locDesc;
      await this.miniProgramService.recalculateCart(this.channelId, this.appLinkTokenResponse.linkId, this.cartModel, true);
    }
    else{
      storeId = this.curStoreId;
      locDesc = this.locDesc;
      await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, true);
    }

    this.storeService.setIsRemoveMenuRowVersion(true);
    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(locDesc);
    this.router.navigate(['store', storeId, locDescWithoutSpaces]);
  }

  async crossSellProcess(menuSets : MenuSet[]){
    if(this.currentCrossSellList.length == 0 && this.orderH && this.orderH?.isSelect
      && menuSets[0].menuRecomm && menuSets[0].menuRecomm?.length > 0 && !this.isStorePage){
      this.currentCrossSellList = await this.cartService.processCrossSellData(menuSets, this.orderH);
    }
    else if (this.currentCrossSellList.length > 0 && !this.orderH && this.isStorePage) {
      this.currentCrossSellList = [];
    }
  }

  // reward
  onApplyPromo() {
    this.rewardService.promoDialog.next({isShow: true, storeId: this.curStoreId});
  }

  onRemovePromo(promotionCode: string) {
    this.rewardService.removePromo.next({promotionCode: promotionCode, storeId: this.curStoreId});
  }

  onSelectVoucher() {
    this.rewardService.selectDialog.next({isShow: true, storeId: this.curStoreId, orderData: this.orderH?.orderData});
    this.rewardService.dialogScrollPosition = 0;
    this.rewardService.seeMoreData = null;
  }

  onRemoveVoucher(voucherNo: string) {
    this.rewardService.removeReward.next({voucherNo: voucherNo, storeId: this.curStoreId});
  }

  // maximum quantity output
  onReachedMaximumQuantity() {
    this.reachedMaximumQuantity.emit();
  }

  initStateData(storeId : number, storeData : any){
    // temporarily stored Store data into a global field
    this.storeRespData = {} as Store;
    this.storeRespData.id = storeId;
    this.storeRespData.storeResponse = storeData.StoreResponse;
    this.storeRespData.storePromotion = storeData.StorePromotion;
    this.storeRespData.storeVoucherType = storeData.StoreVoucherType;
    this.storeRespData.merchantMemberships = storeData.MerchantMemberships;

    // temporarily stored Menu data into a global field
    this.storeMenuData = {} as Menu;
    this.storeMenuData.id = storeId;
    this.storeMenuData.menuResponse = storeData.storeMenuData;

    // temporarily stored storeItemStats data into a global field
    this.storeItemStatsData = {} as StoreItemStatsModel;
    this.storeItemStatsData.id = storeId;
    this.storeItemStatsData.storeItemStats = storeData.StoreItemStats;
  }

  onImgError() {
    this.cardImageError = true
  }

  getQrExpiryDate() {
    this.qrExpiryDateSub$ = this.qrCartService.displayQrExpiryDate$.subscribe(expiryDisplay => {
      this.displayQrExpiryDate = expiryDisplay;
    });
  }
}
