import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { LoginForm } from 'src/app/account/models';
import { ChangeData } from "ngx-intl-tel-input";
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { SessionStorageService } from '../../storage/session-storage.service';
import { SetCode } from 'src/app/core/enums/SetCode';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss']
})
export class GuestInfoComponent implements OnInit {
  @Input() guestInfoDetailSetting: any;
  @Output() onClickSubmitGuestInfo = new EventEmitter();

  fgroup: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    mobileNo: ['', Validators.compose([])],
    email: ['', [Validators.email, Validators.pattern(this.utilsService.emailPattern)]],
  });

  telInpSetting = DefaultSettingService.telInpSetting;
  invalidMobileFormat: boolean = false;
  isLoggedIn: boolean = false;
  guestInfo$: Subscription;
  isUpdate: boolean = false;
  requirField: boolean = false;

  isCustNameRequired: boolean = false;
  isEmailRequired: boolean = false;
  isMobilNoRequired: boolean = false;
  guestInfoSetting: any;

  constructor(
    private fb: UntypedFormBuilder,
    private utilsService: UtilsService,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.getFormControl('mobileNo').valueChanges.subscribe((dt) => {
      this.invalidMobileFormat = false;
    })
  }

  async ngOnInit() {
    this.guestInfo$ = this.userService.getGuestInfo().subscribe((dt: any) => {
      if(dt){
        this.getFormControl('name').setValue(dt?.name ?? '');
        this.getFormControl('mobileNo').setValue(dt?.mobileNo ?? '');
        this.getFormControl('email').setValue(dt?.email ?? '');

        if(dt.name || dt.mobileNo || dt.email){
          this.isUpdate = true;
        }

      }else if(this.sessionStorageService.getItem('guestInfo')) {
        let localGuestInfo = JSON.parse(this.sessionStorageService.getItem('guestInfo'));

        this.getFormControl('name').setValue(localGuestInfo?.name ?? '');
        this.getFormControl('mobileNo').setValue(localGuestInfo?.mobileNo ?? '');
        this.getFormControl('email').setValue(localGuestInfo?.email ?? '');
        this.userService.guestInfo.next({ name: localGuestInfo.name, email: localGuestInfo.email, mobileNo: localGuestInfo.phoneNo });

        if(localGuestInfo && (localGuestInfo?.name || localGuestInfo?.mobileNo || localGuestInfo?.email )){
          this.isUpdate = true;
        }
      }
    });

    this.createFormValidation();
  }

  private createFormValidation() {
    if (this.guestInfoDetailSetting) {
      this.guestInfoSetting = this.guestInfoDetailSetting.find(setting => setting.setCode == SetCode.CAPCUSTINFO);
      //if is optional(setValue = 1) and hide optional (setvalue = 3)
      if (this.guestInfoSetting && (this.guestInfoSetting.setValue === "1" || this.guestInfoSetting.setValue === "3")) {
        let infoField = this.guestInfoDetailSetting.find(setting => setting.setCode == SetCode.CUSTREQINFO);
        if (infoField) {
          let guestReqInfo = infoField.setValue.split(",");
          if (guestReqInfo.indexOf('CUSTNAME') > -1) {
            this.isCustNameRequired = true;
            this.getFormControl('name').setValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
          }

          if (guestReqInfo.indexOf('TELNO') > -1) {
            this.isMobilNoRequired = true;
            this.getFormControl('mobileNo').setValidators([Validators.compose([Validators.required])]);
          }

          if (guestReqInfo.indexOf('EMAIL') > -1) {
            this.isEmailRequired = true;
            this.getFormControl('email').setValidators([Validators.required, Validators.email, Validators.pattern(this.utilsService.emailPattern)]);
          }
        }
      }
      // if all field required
      else if (this.guestInfoSetting && this.guestInfoSetting.setValue === "2") {
        this.isCustNameRequired = true;
        this.getFormControl('name').setValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
        this.isMobilNoRequired = true;
        this.getFormControl('mobileNo').setValidators([Validators.compose([Validators.required])]);
        this.isEmailRequired = true;
        this.getFormControl('email').setValidators([Validators.required, Validators.email, Validators.pattern(this.utilsService.emailPattern)]);
      }
    }
  }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  get fg() { return this.fgroup; }
  get f() { return this.fg.controls; }

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number ? phoneObj.e164Number.replace(phoneObj.dialCode || '', '') : '';
  }

  async onClickContinue() {
    let name = this.getFormControl('name').value;
    let mobileNo = this.getFormControl('mobileNo').value;
    let email = this.getFormControl('email').value;

    let mobileData: any = null;
    if (mobileNo) {
      const mobile = mobileNo.e164Number;
      let invalidPhoneNo: boolean = await this.utilsService.phoneNoValidation(mobile);
      if (invalidPhoneNo) {
        this.invalidMobileFormat = true;
        return;
      } else {
        mobileData = mobile.replace('+', '') + this.formatPhoneNo(mobile);
      }
    }

    if (email && this.f.email.errors?.pattern) {
      return;
    }

    if (this.fg.invalid) {
      this.requirField = true;
      return;
    } else {
      const guestInfo: LoginForm = {
        name: name,
        phoneNo: mobileData,
        email: email,
      }

      this.onClickSubmitGuestInfo.emit(guestInfo);
    }
  }

  ngOnDestroy() {
    this.guestInfo$?.unsubscribe();
  }
}
