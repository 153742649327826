import { Component, Input, OnInit, Output, OnDestroy, EventEmitter } from '@angular/core';
import { CountdownEvent } from 'ngx-countdown';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  @Input() timeInSec = new Observable<number>();
  @Input() notifySec: number = 29;
  @Output() onCallback = new EventEmitter();
  @Output() onShowBackButton = new EventEmitter<boolean>();

  sub: Subscription = new Subscription();
  sub2: Subscription = new Subscription();
  isShow: boolean = true;
  countdownTimeSec: number = 0;
  numberOfTime: number = environment.optMaxFailBypass;

  constructor() { }

  ngOnInit(): void {
    this.sub = this.timeInSec.subscribe((obs) => {
      this.countdownTimeSec = obs;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  handleEvent(event: CountdownEvent) {
    if (event.action === 'start') {
      this.onShowBackButton.emit(false);
    }

    if (event.action === 'notify') {
      this.onShowBackButton.emit(true);
    }

    if (event.action === 'done' && this.numberOfTime > 0) {
      this.numberOfTime--;

      this.onCallback.emit();
    }
  }
}
