<div *ngIf="selectedChannel" [ngClass]="{'header-expiry': displayQrExpiryDate}">
  <app-download-app class="hidden-sm" [hidden]="displayQrExpiryDate"></app-download-app>

  <!-- qr countdown timer -->
  <app-order-countdown [hidden]="!displayQrExpiryDate" [expiryTime]="expiryTime" [serverTime]="serverTime"
    (onShowExpiryDate)="onShowExpiryDate($event)" (onTimeup)="onTimeup()"></app-order-countdown>

  <!--desktop view header-->
  <div class="app-header-container hidden-sm" #header>
    <div class="app-header" [ngClass]="{ 'sticky': isSticky, 'sticky-expiry': isSticky && displayQrExpiryDate }">

      <i class="oda-bars" (click)="menuSidebar = true"></i>

      <div class="home-btn" (click)="clickLogo()" [hidden]="isBlockBack">
        <img class="channel-logo-img"
          *ngIf="selectedChannel?.imageLink && !(selectedChannel?.channelTag === 'MY' && selectedChannel?.countryCode === 'MY'); else elseBlock"
          [src]="selectedChannel?.imageLink" alt="channel_logo" loading="lazy" />
        <ng-template #elseBlock>
          <img src="assets/odaring-logo.svg" alt="channel_logo" loading="lazy">
        </ng-template>
      </div>

      <!--oder type & location-->
      <div class="orderType-location-container" [style.z-index]="displayAddressTooltip ? 100 : 'auto'"
        *ngIf="curRouter === 'home' || (curRouter === 'store' && currentOrderType == orderTypeFlag.Delivery)">
        <app-order-type *ngIf="curRouter === 'home'" (onOrderTypeChanged)="onOrderTypeChangedFunc($event)">
        </app-order-type>
        <span *ngIf="curRouter === 'home'" class="grapline"></span>
        <app-location [isUserLogin]="isUserLogin" (click)="onClickLocation()"></app-location>

        <!-- tooltip when address is forcefully changed -->
        <span class="address-tooltip">
          <app-tooltip></app-tooltip>
        </span>
      </div>

      <app-toolbar [curRouter]="curRouter" (onShowChannel)="showChannel()"></app-toolbar>
    </div>
  </div>

  <div class="home-sidebar">
    <!--sidebar-->
    <p-sidebar [(visible)]="menuSidebar" [baseZIndex]="10000" [blockScroll]="true">

      <img src="assets/odaring-logo.svg" class="logo-header" alt="odaring logo"/>

      <div class="sidebar-menu">
          <app-language></app-language>
          <a (click)="menuSidebar = false; onNavigate('email')">
            <i class="oda-mail"></i>{{'navigation.Menu.des.12' | translate}}
          </a>
          <a (click)="menuSidebar = false; onNavigate('faq')">
            <i class="oda-faq"></i>{{'navigation.Menu.des.13' | translate}}
          </a>
          <a (click)="menuSidebar = false; onNavigate('privacyPolicy')">
            <i class="oda-shield-lock"></i>{{'navigation.Menu.des.14' | translate}}
          </a>
          <a (click)="menuSidebar = false; onNavigate('tNc')">
            <i class="oda-card-info"></i>{{'navigation.Menu.des.15' | translate}}
          </a>

          <hr />

          <a (click)="menuSidebar = false; onNavigate('aboutUs')">{{'navigation.Menu.des.16' | translate}}</a>
          <a (click)="menuSidebar = false; onNavigate('beComeAPartner')">{{'navigation.Menu.des.17' | translate}}</a>
      </div>
    </p-sidebar>
  </div>

  <!-- Web Display Channel -->
  <div class="toolbar_container-channel-modal hidden-sm">
    <p-dialog header=" " [(visible)]="displayChannel" [baseZIndex]="10000" [modal]="true" [dismissableMask]="true"
      [blockScroll]="true">

      <div class="p-d-flex">
        <div class="switchChannel_leftPanel">
          <!--header-->
          <div class="p-d-flex p-mb-4">
            <div class="heading-4">{{'toolbar.switchChannel.dialog.header.1' | translate}}</div>
            <div class="p-ml-auto" *ngIf="userchnlList.length !== 0 && isUserLogin">
              <button class="leftPanel-btn-delete" (click)="isDeleteMode = !isDeleteMode"><i
                  class="duotone oda-d-trash"></i></button>
            </div>
          </div>

          <p-scrollPanel [style]="{width: '100%', height: '400px'}">
            <p-table [value]="userchnlList" sortField="channelId" sortMode="single" (onSort)="onSort()">
              <ng-template pTemplate="body" let-channelList let-rowIndex="rowIndex">
                <tr *ngIf="channelList.status" style="background-color: transparent;">
                  <div colspan="5" [ngClass]="{ 'channel-status': rowIndex > 1 }">
                    <span
                      style="font-size: 16px; font-weight: bold; color: #B1B1B1;">{{getCustomerChannelStatus(channelList.status)
                      | translate}}</span>
                  </div>
                </tr>
                <tr *ngFor="let channel of channelList.data" style="background-color: transparent;"
                  (click)="switchChannelByTag(channel.channelTag)">
                  <div class="row" style="margin: 0px;">
                    <div class="channel-line">#{{channel.channelTag}}</div>
                    <span class="channel-remove" *ngIf="isDeleteMode && !channel.showDeleteButton"
                      (click)="channel.showDeleteButton = !channel.showDeleteButton; $event.stopPropagation()">
                      <i class="oda-times"></i>
                    </span>
                    <div style="margin-left: auto; display: flex; align-self: center;" *ngIf="channel.showDeleteButton">
                      <button class="channel-cancel-button"
                        (click)="$event.stopPropagation(); channel.showDeleteButton = false">{{'button.cancel' |
                        translate}}</button>
                      <button class="channel-delete-button"
                        (click)="$event.stopPropagation(); deleteChannel(channel)">{{'button.remove' |
                        translate}}</button>
                    </div>
                  </div>
                </tr>
                <tr *ngIf="channelList.data.length === 0" style="display: inline-block;">
                </tr>
              </ng-template>
            </p-table>

          </p-scrollPanel>
        </div>

        <div class="switchChannel_rightPanel">
          <div class="back-btn" (click)="displayChannel = false">
            <i class="oda-times"></i>
          </div>
          <div class="p-d-flex">
            <div class="switchChannel-heading">{{'toolbar.switchChannel.dialog.header.2' | translate}}</div>
            <div class="p-ml-auto" *ngIf="selectedChannel && selectedChannel.channelTag !== odaringChannel">
              <button class="switchChannel_btn-back" (click)="switchChannelByTag()">
                {{'button.back.to' | translate}} <img src="assets/odaring-logo.svg" alt="odaring logo"/>
              </button>
            </div>
          </div>

          <div class="switchChannel-label" style="margin-top: 30px">{{'input.label.14' | translate}}<span
              >*</span></div>

          <form (ngSubmit)="onSubmit()" [formGroup]="fgroup" class="p-mt-2">

            <div class="p-float-label">
              <i class="oda-channel channel_icon p-ml-2"></i>
              <input type="text" pInputText placeholder="Odaring" style="padding-left: 41px;"
                formControlName="switchChannel"
                [ngClass]="{ 'is-invalid': f.switchChannel.touched && f.switchChannel.errors }" #channelname autofocus />
              <i class="oda-times channelinput-btn-clear" *ngIf="channelname.value.length > 0"
                (click)="f.switchChannel.setValue('')"></i>
            </div>

            <div *ngIf="f.switchChannel.touched && f.switchChannel.errors">
              <div *ngIf="f.switchChannel.errors.required">
                <div class="p-mt-2 channel-errormsg-container-2 ">
                  <span>
                    <i class="oda-alert"></i>{{'alert.switch.channel.des.4'| translate }}
                  </span>
                </div>
              </div>
            </div>

            <div >
              <div class="p-d-flex p-mt-2 channel-msg-container"
              *ngIf="switchChannelError && switchChannelError.error.errorCode === errorCode.PendingApprovalChannelAccess_403">
                <span><i class="oda-check-alt"></i> {{'PendingApprovalChannelAccess_403'|
                  translate}}</span>
                <span class="p-ml-auto okay-btn" (click)="switchChannelError = null">{{ 'button.ok' | translate
                  }}</span>
              </div>

              <div class="p-d-flex p-mt-2 channel-exp-container"
              *ngIf="switchChannelError && switchChannelError.error.errorCode === errorCode.ExpiredChannelAccess_403">
                <i class="oda-info"></i>
                <span>{{'ExpiredChannelAccess_403' | translate}}</span>
                <span class="p-ml-auto okay-btn" (click)="switchChannelError = null">{{ 'button.ok' | translate
                  }}</span>
              </div>

              <div class="p-d-flex p-mt-2 channel-errormsg-container"
              *ngIf="switchChannelError && switchChannelError.error.errorCode === errorCode.ForbiddenChannelAccess_403">
                <span><i class="oda-alert"
                    style="vertical-align: text-top;"></i>{{'alert.switch.channel.des.2'| translate }}</span>
                <span class="p-ml-auto okay-btn" routerLinkActive="active" (click)="onLogin()">{{ 'button.login' |
                  translate }}</span>
              </div>

              <div class="p-mt-2 channel-errormsg-container-2"
              *ngIf="(switchChannelError && switchChannelError.error.errorCode === errorCode.ChannelNotFound_404) || switchChannelNotFound">
                <span><i
                    class="oda-alert"></i> {{'ChannelNotFound_404'| translate }}</span>
              </div>
            </div>

            <button type="submit" class="heading-11 channel_btn-switch"
              [ngClass]="{'disabled-button': !f.switchChannel.value}" [disabled]="!f.switchChannel.value">
              {{ 'button.switch' | translate }}
            </button>
          </form>
        </div>

      </div>

    </p-dialog>
  </div>


  <!--mobile header-->
  <div *ngIf="showMobileHeader" class="hidden-lg app-header-mobile" #headerMobile>
    <div class="top-content" [ngClass]="{ 'sticky': isMobileSticky }">

      <div class="scan-panel">
        <i class="oda-scan" (click)="showScannerDialog()"></i>
      </div>

      <div class="orderType-location-mobile-container" (click)="onClickOrderTypeLocation()">
        <div class="mobile-order-type">
          <i [ngClass]="orderTypeicon"></i>
          <h1>{{orderTypeDesc | translate}}</h1>
        </div>

        <!-- address display for mobile view -->
        <div class="mobile-location" *ngIf="orderType !== orderTypeFlag.DineIn || !shareTable">
          {{fulladdress ? fulladdress : 'delivery.page.hint.text.field' | translate}}
        </div>

        <!-- table number desc for mobile view -->
        <div class="heading-11" style="cursor: pointer;"
          [style.color]="selectedChannel?.foreColor ? '#' + selectedChannel?.foreColor : '000000'"
          *ngIf="orderType == orderTypeFlag.DineIn && shareTable">
            <label class="dine-in-desc" style="margin: 0; cursor: pointer;" *ngIf="tblNoLbl">{{staticTableNo ? tblNoLbl + ' ' + staticTableNo : ('location.scan.desc' | translate)}}</label>
            <label class="dine-in-desc" style="margin: 0; cursor: pointer;" *ngIf="!tblNoLbl">
              {{staticTableNo ?  ("merchant.qr.dinein.table.no" | translate : { tableNo: staticTableNo }) : ('location.scan.desc' | translate)}}
            </label>
        </div>
      </div>

      <span *ngIf="isDefaultDomain && selectedChannel" class="channel-desc" [ngStyle]="{'border-color': isSubChannel ? '#FFFFFF' : 'transparent',
          'background-color': isSubChannel ? '#FFFFFF' : 'transparent'}" (click)="showChannel()">
        <i *ngIf="!isSubChannel" class="oda-channel mobile-switch-channel"></i>
        <img *ngIf="isSubChannel" class="channel-image" src={{selectedChannel.imageLink}} alt="channel logo" loading="lazy">
      </span>

      <i *ngIf="!isDefaultDomain" class="oda-channel subdomain-switch-channel"></i>
    </div>
  </div>

  <!-- Dialog here -->
  <div class="toolbar_container-channel-modal-mobile hidden-lg">
    <p-dialog class="switch-channel-dialog" [ngClass]="{'without-grid-content' : isUserLogin}"
      [(visible)]="displayChannel" [modal]=true [showHeader]="true" [closable]="false" [blockScroll]="true">
      <!-- Header -->
      <ng-template pTemplate="header">
        <i class="btn-back oda-chevron-left" (click)="displayChannel = false"></i>
        <div class="heading">{{ 'alert.switch.channel.des.5' | translate }}</div>
      </ng-template>

      <!-- Content -->
      <form class="channel-name-form" (ngSubmit)="onSubmit()" [formGroup]="fgroup">
        <div class="p-float-label switch-channel-container">
          <i class="oda-channel hashtag"></i>
          <input type="text" pInputText placeholder="{{ 'input.label.14' | translate }}" formControlName="switchChannel"
            [ngClass]="{ 'is-invalid': f.switchChannel.touched && f.switchChannel.errors }" #channelname autofocus />
          <button type="submit" class="btn-switch" [ngClass]="{'disabled-button': !f.switchChannel.value}"
            [disabled]="!f.switchChannel.value">
            <i class="duotone oda-d-switch"></i>
          </button>
        </div>
        <div *ngIf="f.switchChannel.touched && f.switchChannel.errors">
          <div *ngIf="f.switchChannel.errors.required">
            <div class="error-container">
              <span class="error"><i class="oda-alert" style="padding-bottom: 1px;"></i>{{'alert.switch.channel.des.4'|
                translate }}</span>
            </div>
          </div>
        </div>
        <div class="error-container" *ngIf="switchChannelError?.status === 404 || switchChannelNotFound">
          <span class="error"><i class="oda-alert" style="padding-bottom: 1px;"></i>{{'ChannelNotFound_404'| translate
            }}</span>
        </div>
      </form>

      <div *ngIf="curUser != null; else chnlLogin">
        <div class="option-container">
          <div class="left-option">

            <div style="display: flex" *ngFor="let channel of userchnlList">
              <div class="option-wrapper" (click)="onSwitchChnlStatus(channel.status);"
                [class.active]="selectedChnlStatus === channel.status">
                <h2 class="background">{{ getCustomerChannelStatus(channel.status) | translate }}
                  ({{channel.data.length}})</h2>
              </div>
            </div>
          </div>
          <div class="right-option">
            <button type="button" class="btn-clear" (click)="isDeleteMode = !isDeleteMode">
              <i class="duotone oda-d-trash" *ngIf="!isDeleteMode"></i>
              <p *ngIf="isDeleteMode">{{ 'button.done' | translate | uppercase }}</p>
            </button>
          </div>
        </div>

        <div class="listing-container" *ngFor="let channel of userchnlList">
          <div *ngIf="channel.status === selectedChnlStatus">
            <div *ngIf="channel.data.length > 0; else chnlDataNotFound">
              <div class="listing-card" *ngFor="let channelData of channel.data">
                <div class="listing-content" (click)="switchChannelByTag(channelData.channelTag)">
                  <img [src]="channelData.imageLink" alt="channel image" loading="lazy"/>
                  <span class="heading-12">#{{ channelData.channelTag }}</span>
                </div>
                <i class="oda-remove-circle listing-button" *ngIf="isDeleteMode"
                  (click)="deleteChnlData = channelData;"></i>
              </div>
            </div>
            <ng-template #chnlDataNotFound>
              <div class="listing-not-found">
                <p class="heading-12">{{ 'alert.switch.channel.des.6' | translate }}</p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <ng-template #chnlLogin>
        <div class="channel-login">
          <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
          <p class="heading-12">{{ 'alert.switch.channel.des.7' | translate }}</p>
          <button type="button" (click)="onLogin()">{{ 'button.login' | translate }}</button>
        </div>
      </ng-template>

      <div *ngIf="isUserLogin" style="position: absolute; bottom: 50px;"
        [ngStyle]="{'right': isDeleteMode ? '-105px' : '-20px'}">
        <img src="assets/channel-circle-background.svg" style=" width: auto; height: 120px; position: relative" alt="channel image"/>

        <div style=" position: absolute; top: 40px; left: 25px; ">
          <button class="heading-17" style="display: grid;" (click)="switchChannelByTag()">
            {{'button.back.to' | translate}} <img src="assets/odaring-logo.svg" style="width: 75px; height: 21px;" alt="odaring logo"/>
          </button>
        </div>
      </div>

      <div *ngIf="!isUserLogin" class="bottom-back-odaring-channel">
        <label (click)="switchChannelByTag()">
          {{'button.back.to' | translate}}
          <img src="assets/odaring-logo.svg" alt="odaring logo"/>
        </label>
      </div>
    </p-dialog>

    <!-- Delete channel -->
    <p-dialog class="mobile-dialog" [(visible)]="deleteChnlData" [modal]=true [showHeader]=false [closable]=true>
      <div class="icon-container"><i class="duotone oda-d-trash"></i></div>
      <div class="desc heading-12">{{ 'alert.delete.channel.desc' | translate }}</div>
      <div class="button-container">
        <button type="button" class="btn-yes"
          (click)="deleteChannel(deleteChnlData); deleteChnlData = null;">{{'button.remove.Channel' |
          translate}}</button>
        <button type="button" class="btn-no" (click)="deleteChnlData = null;">{{'button.cancel' | translate}}</button>
      </div>
    </p-dialog>

    <!-- Request access channel (403) -->
    <p-dialog
      *ngIf="switchChannelError && switchChannelError.error.errorCode === errorCode.PendingApprovalChannelAccess_403"
      [(visible)]="switchChannelError" class="mobile-dialog" [visible]="true" [modal]=true [showHeader]="false"
      [closable]="true" [dismissableMask]="true">
      <div class="icon-container"><i class="oda-channel"></i></div>
      <div class="desc heading-12">{{ 'PendingApprovalChannelAccess_403' | translate }}</div>
      <div class="button-container">
        <button type="button" class="btn-ok" (click)="switchChannelError = null">{{'button.ok' | translate}}</button>
      </div>
    </p-dialog>

    <p-dialog *ngIf="switchChannelError && switchChannelError.error.errorCode === errorCode.ExpiredChannelAccess_403"
      [(visible)]="switchChannelError" class="mobile-dialog" [visible]="true" [modal]=true [showHeader]="false"
      [closable]="true" [dismissableMask]="true">
      <div class="icon-container"><i class="oda-channel"></i></div>
      <div class="title">{{'alert.channel.switch.expired' | translate}}</div>
      <div class="desc heading-12">{{ 'ExpiredChannelAccess_403' | translate }}</div>
      <div class="button-container">
        <button type="button" class="btn-ok" (click)="switchChannelError = null">{{'button.ok' | translate}}</button>
      </div>
    </p-dialog>
  </div>

</div>
