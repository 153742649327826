
<div class="cart-bg" [ngClass]="{'no-bg-when-empty': ((cartModel == null || orderH?.length == 0) && !qrTokenResponse) ||
((cartModel == null || orderH?.length == 0) && (!submittedOrder || submittedOrder.length == 0) && qrTokenResponse)}" *ngIf="!isLoading">

  <div class="mobile-cart-header hidden-lg">
    <div class="back-button-container">
      <app-back-button
        [manualBackEvent]="true"
        [color]="'var(--backbutton)'"
        [fontSize]="'15px'"
        (onManualBackEvent)="manualCartBack()">
      </app-back-button>
    </div>
    <h1 class="mobile-cart-title p-text-center">{{'order.payment.header.3' | translate }}</h1>

    <button class="qr-login-button" [ngClass]="{'no-attention-message': !attentionOrders || attentionOrders?.length == 0,
     'got-attention-message': attentionOrders && attentionOrders?.length > 0}"
     *ngIf="qrTokenResponse && !isLoggedIn" (click)="navigateToLogin()">{{"button.login" | translate}}</button>
    <i class="oda-alert" (click)="openAttentionDialog()" *ngIf="attentionOrders?.length > 0"></i>
  </div>

  <app-empty-dish-message
    *ngIf="((cartModel == null || orderH.length == 0) && !qrTokenResponse) ||
    (((cartModel == null || orderH.length == 0) && submittedOrder && submittedOrder?.length == 0) && qrTokenResponse)"
    [isCartPage]="true"
    [qrTokenResponse]="qrTokenResponse"
    [queueResponse]="queueResponse"
    [appLinkTokenResponse]="appLinkTokenResponse"
    [channelId]="channelId">
  </app-empty-dish-message>

  <div class="cart-container" *ngIf="cartModel != null && orderH.length != 0" [style.margin-bottom]="qrTokenResponse && submittedOrder? '0px': '110px'">
    <div>
      <div *ngFor="let item of orderH | cartStoreBind : orderH : storeData : menuState : storeItemStatsData | async; let i = index; trackBy: trackByFn" style="margin-bottom: 15px;">
        <app-cart-form
          [curStoreId]="item.orderH.storeId"
          [locDesc]="item.orderH.storeName"
          [orderHInput]="item.orderH"
          [qrTokenResponse]="qrTokenResponse"
          [cartModelInput]="cartModel"
          [storeData]="item.store"
          [queueResponse]="queueResponse"
          [staticQrData]="staticQrData"
          [appLinkTokenResponse]="appLinkTokenResponse"
          (reachedMaximumQuantity)="displayMaximumQuantityDialog()"
          (checkStore)="checkIfStoreExists()"
          (checkAttentionMessage)="checkAttentionMessage($event)">
        </app-cart-form>
      </div>
    </div>
  </div>

  <div class="qr-back-button-container hidden-sm" *ngIf="qrTokenResponse && submittedOrder && submittedOrder.length != 0 && orderH.length == 0">
    <div class="qr-back-button" (click)="backToPreviousPage()">
      <i class="oda-chevron-left"></i>
      <span class="button-description">{{"button.back" | translate}}</span>
    </div>
  </div>

  <div class="submitted-order-container" *ngIf="qrTokenResponse && submittedOrder && submittedOrder.length != 0">
    <div class="title-container p-d-flex">
      <i class="oda-red-loading"></i>
      <h1 class="title-description heading-7">{{"cart.submitted.order.des.1" | translate : {orders: submittedOrder.length} }}</h1>
    </div>

    <div>
      <div class="submitted-order-form" *ngFor="let orders of submittedOrder">
        <app-cart-submitted-orders [detailActivity]="orders"></app-cart-submitted-orders>
      </div>
    </div>
  </div>

  <div class="cart-action-containter" *ngIf="!qrTokenResponse && !queueResponse && orderH.length > 0">
    <div class="cart-action-buttons">
      <!-- select all checkbox -->
      <div class="btn-back-container" *ngIf="orderH && orderH.length > 1 && !orderAllTable">
        <div class="select-all-checkbox p-mr-1" [style.border]="(noOfSelected != 0 && allSelected) ? 'none' : '1px solid #b1b1b1' "
        [style.background-color]="(noOfSelected != 0 && allSelected) ? 'var(--iconTextButton-primary)' : '#FFFFFF'" (click)="selectAllStore()">
          <i class="oda-check" *ngIf="noOfSelected != 0 && allSelected"></i>
        </div>
        <span class="select-all-desc">{{"cart.all.button" | translate}}</span>
      </div>

      <!-- pay for button -->
      <button type="button"
        [ngClass]="{'button-when-empty': cartModel == null || noOfSelected == 0 || cartModel.itemQty == 0}" [disabled]="cartModel.itemQty == 0"
        [style.max-width]="orderH && orderH.length > 1 && !orderAllTable ? '450px': 'none'"
        class="btn-reorder order-summary-heading-2 heading-10"
        (click)="cartModel != null ? onClickPay() : ''">
        <span [innerHtml]="'button.pay.for.price' | translate: { price : '<span style=\'font-weight: bold\'>' + (totalPrice | currency: currCode : 'symbol-narrow') + '</span>' } | safeHtml"></span>
        <span class="p-ml-1" *ngIf="noOfSelected > 1">({{noOfSelected}})</span>
      </button>
    </div>
  </div>

  <div class="p-d-flex confirm-action-containter" *ngIf="qrTokenResponse && orderH.length > 0">
    <button type="button" [ngClass]="{'button-when-empty': cartModel == null || cartModel.itemQty == 0}" class="btn-confirm heading-11" [disabled]="isProcessing || cartModel.itemQty == 0"
    (click)="(cartModel != null) ? onClickConfirm() : ''">
      {{"merchant.detail.button.confirm.2" | translate }}
    </button>
  </div>

  <div class="p-d-flex queue-button-container" *ngIf="queueResponse && (orderH.length > 0 && cartModel)">
    <button class="back-to-queue-btn heading-11"
      *ngIf="queueResponse.statusFlag == queueStatusFlag.Active"
      (click)="backToQueue()">
      {{"button.back.to.queue" | translate}}
    </button>
    <button type="button" class="btn-confirm heading-11" (click)="queueConfirmOrder()" *ngIf="queueResponse.statusFlag == queueStatusFlag.Called">
      {{"merchant.detail.button.confirm" | translate }}
    </button>
  </div>
</div>

<div class="order-attention-button hidden-sm" *ngIf="attentionOrders?.length > 0" (click)="openAttentionDialog()">
  <i class="oda-alert"></i>
  <span>{{"order.attention.header" | translate}}</span>
</div>

<div class="login-dialog-container">
  <p-dialog [(visible)]="displayLoginPrompt" [position]="dialogPosition" [draggable]=false [dismissableMask]="dismissable" [modal]="true" [closable]='true' *ngIf="displayLoginPrompt" (visibleChange)="visibleChgLoginDialog($event)">
    <i class="oda-times close-icon" (click)="closeLoginDialog()" *ngIf="!isMobileView"></i>

    <app-login-message (navigateToLogin)="navigateToLogin()" [channelId]="channelId"></app-login-message>

  </p-dialog>
</div>

<div class="login-dialog-container externalLogin-dialog-container">
  <p-dialog [(visible)]="displayExternalLoginPrompt" [position]="dialogPosition" [draggable]=false [dismissableMask]="dismissable" [modal]="true" [closable]='true' *ngIf="displayExternalLoginPrompt" (visibleChange)="visibleChgLoginDialog($event)">
    <i class="oda-times close-icon" (click)="closeLoginDialog()" *ngIf="!isMobileView"></i>

    <app-external-membership-login [membershipCode]="membershipCode" [channelId]="channelId" [cardImageData]="externalMemberCardImageData" [logoImageData]="externalMemberLogoImageData" [membershipDesc]="externalMemberMembershipDesc"></app-external-membership-login>

  </p-dialog>
</div>


<!-- attention message dialog -->
<div class="attention-dialog" *ngIf="!isLoading">
  <p-dialog id="attention-dialog" [(visible)]="displayAttentionDialog" [position]="dialogPosition" [draggable]=false [dismissableMask]="dismissable" [modal]="true" [closable]='true' *ngIf="displayAttentionDialog">

  <ng-template pTemplate="header">
    <div class="attention-header p-d-flex">
      <i class="oda-alert"></i>
      <span class="header-description">{{"order.attention.header" | translate}}</span>
    </div>

    <i class="oda-times" (click)="closeAttentionDialog()"></i>
  </ng-template>

  <ng-template pTemplate="content">
    <div class='attention-content-container'>
      <div *ngFor="let order of attentionOrders; let i = index">
        <div class="store-name">
          {{order.storeName}}
        </div>

        <div class="attention-description-container p-d-flex" *ngFor="let message of order.attentionMessages">
          <span class="attention-description"*ngIf="message.messageCode != 'OrderItemBalanceQtyInvalid_400' && message.messageCode != 'ItemBlockedNotSync_204'">
            {{message.messageData}}
          </span>
          <span class="attention-description" *ngIf="message.messageCode === 'OrderItemBalanceQtyInvalid_400'"
          [innerHtml]="'order.item.balance.qty.invalid' | translate: { itemName: constructInvalidQtySentence(message.messageData).itemDesc, qtyBalance: constructInvalidQtySentence(message.messageData).balance }">
          </span>
          <span class="attention-description" *ngIf="message.messageCode === 'ItemBlockedNotSync_204'" [innerHtml]="'block.item.description' | translate: { itemName: message.messageData }">
          </span>
        </div>

        <hr *ngIf="i + 1 != attentionOrders.length"/>
      </div>
    </div>
  </ng-template>

  </p-dialog>
</div>

<div class="qr-merge-message">
  <p-dialog header=" " [(visible)]="displayMergeMessage" [position]="dialogPosition" [dismissableMask]="dismissable" [style]="{width: '520px'}"
    [baseZIndex]="10000" [modal]="true" [closable]='true'>
    <i class="oda-times" (click)="closeMergeMessageDialog()"></i>
    <app-qr-merge-message (mergeOrder)="mergeOrder()" (splitOrder)="splitOrder()" [custname]="mergeCustName" [channelId]="channelId"></app-qr-merge-message>
  </p-dialog>
</div>

<!-- select voucher dialog -->
<p-dialog [(visible)]="displaySelectVoucherDialog" [modal]=true [draggable]=false [dismissableMask]="true" class="select-voucher-dialog" *ngIf="displaySelectVoucherDialog">
  <app-select-voucher [storeId]="selectVoucherStoreId" [applicableVouchers]="applicableVoucher" [selectedVouchers]="appliedVoucher" [orderData]="selectVoucherOrderData" [showEnterVoucherCode]="showEnterVoucherCode"></app-select-voucher>
</p-dialog>

<!-- apply promo dialog -->
<p-dialog [(visible)]="displayPromoDialog" [modal]=true [draggable]=false [dismissableMask]="true" class="promo-dialog" *ngIf="displayPromoDialog">
  <app-apply-promo [searchPromoCode]="searchPromoCode" [invalidPromo]="invalidPromo" [invalidPromoMsg]="invalidPromoMsg" (onApplyPromo)="onApplyPromo($event)"></app-apply-promo>
</p-dialog>

<!-- search voucher code dialog-->
<p-dialog [(visible)]="displaySearchVoucherDialog" [modal]=true [draggable]=false [dismissableMask]="true" class="search-voucher-dialog" [showHeader]="false" *ngIf="displaySearchVoucherDialog">
  <app-search-voucher-code [storeId]="selectVoucherStoreId" [isLoggedIn]="isLoggedIn"></app-search-voucher-code>
</p-dialog>

<!-- voucher detail dialog -->
<p-dialog [(visible)]="displayVoucherDetailDialog" [modal]=true [draggable]=false class="voucher-detail-dialog" [showHeader]="false" *ngIf="displayVoucherDetailDialog && selectVoucherDetail">
  <app-reward-detail
    [myReward]="selectVoucherDetail"
    [storeId]="selectVoucherStoreId"
    [customCardType]="selectVoucherCatg.multiFlag > 0? cardTypes.multiSelect: cardTypes.singleSelect"
    [active]="isVoucherSelected"
    [appLinkTokenResponse]="appLinkTokenResponse"
    [channelId]="channelId">
  </app-reward-detail>
</p-dialog>

<!-- share dialog -->
<p-dialog [(visible)]="displayShareDialog" [modal]=true [draggable]=false class="share-dialog" (visibleChange)="displayVoucherDetailDialog = true" *ngIf="displayShareDialog">
  <h1 class="title">{{ 'button.share' | translate }}</h1>
  <div class="share-btn">
    <app-social-share [url]="shareUrl" [title]="shareStoreName" [description]="shareStoreName? ('share.store.description' | translate: { storeName: shareStoreName }): ''"></app-social-share>
  </div>
  <h2 class="sub-title">{{ 'copy.link' | translate}}</h2>
  <div class="url-container">
    <div class="heading-9 url-sub-content">
      <h1 class="heading-9 text-truncate" name="url-content" id="url-content">{{ shareUrl }}</h1>
    </div>
    <div class="copy-btn">
      <ngx-copy-to-clipboard target="#url-content" [success]="onUrlCopied"><i class="oda-copy"></i></ngx-copy-to-clipboard>
    </div>
    <div class="copied-text" *ngIf="isCopied">{{ "rewards.copied" | translate }}</div>
  </div>
</p-dialog>

<div class="mobile-maximum-dialog">
  <p-dialog [(visible)]="displayMaximumMessage" position="bottom" [dismissableMask]="true" [baseZIndex]="13000"
  [modal]="true" [closable]='true' (onHide)="onMobileMaximumHide()">
    <i class="oda-alert-alt"></i>
    <p class="heading-12">{{"merchant.menu.choice.max" | translate}}</p>
  </p-dialog>
</div>

<!-- verify phone no dialog -->
<p-dialog [(visible)]="displayVerifyPhoneDialog" [modal]=true [draggable]=false [dismissableMask]="true" [showHeader]="false" class="verify-phone-dialog" *ngIf="displayVerifyPhoneDialog" >
  <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
  <h1>{{ "alert.cart.verify.phone.number.desc.1" | translate }}</h1>
  <p>{{ "alert.cart.verify.phone.number.desc.2" | translate }}</p>
  <button type="button" (click)="onClickVerifyPhoneNo()">{{ "button.cart.verify.phone.number" | translate }}</button>
</p-dialog>

<div class="order-paid-dialog">
  <p-dialog [(visible)]="displayOrderPaid" [modal]="true" [position]="dialogPosition" [dismissableMask]="true" [closable]="true"
   [draggable]=false [style]="{width: '400px'}" (onHide)="onOrderPaidClose()">
    <i class="oda-times hidden-sm" (click)="closeOrderPaidDialog()"></i>
    <app-order-paid-message (onCloseMessage)="closeOrderPaidDialog()" [isOrderPaid]="isOrderPaid" *ngIf="displayOrderPaid"></app-order-paid-message>
  </p-dialog>
</div>

<div class="queue-remove-cart">
  <p-dialog [(visible)]="displayRemoveQueueCart" [modal]="true" [position]="dialogPosition" [dismissableMask]="true" [closable]="true"
  [draggable]=false [style]="{width: '400px'}">
   <app-queue-remove-cart
    (cancelRemove)="cancelRemove()"
    (removeItems)="removeItem()"
    *ngIf="displayRemoveQueueCart">
    </app-queue-remove-cart>
 </p-dialog>
</div>

<div class="queue-confirm-error">
  <p-dialog [(visible)]="displayQueueError" [modal]="true" [position]="dialogPosition" [dismissableMask]="true" [closable]="true"
  [draggable]=false>
    <app-confirmed-error
      (retry)="closeErrorMessage()"
      *ngIf="displayQueueError">
    </app-confirmed-error>
  </p-dialog>
</div>

<!-- Capture Phone Dialog -->
<div class="cart-submit-phone">
  <p-dialog [(visible)]="displaySubmitPhoneNo" [position]="dialogPosition" [dismissableMask]="dismissable" [draggable]="false"
  [style]="{width:'580px'}" [baseZIndex]="10000" [modal]="true" [closable]='true'>
    <app-submit-phone-no
      (onClickClose)="closeSubmitPhoneNoDialog()"
      (onClickSubmit)="onSubmitMobileNumber($event)"
      [channelId]="channelId"
      *ngIf="displaySubmitPhoneNo">
    </app-submit-phone-no>
  </p-dialog>
</div>

<div class="tableMissing-dialog">
  <p-dialog [(visible)]="displayScanTableNo" [modal]="true" [position]="dialogPosition" [dismissableMask]="true" [closable]="true"
  [draggable]=false>
    <i class="oda-times" (click)="onCloseOrScanTable()"></i>
    <div class="tableMissing-content">
      <i class="oda-remove-circle"></i>
      <h1>{{'missing.table.number'| translate}}</h1>
      <p>{{'missing.table.number.desc'| translate}}</p>
      <button (click)="onCloseOrScanTable('scan')">{{'scan.table.qr' | translate}}</button>
    </div>
  </p-dialog>
</div>

<div class="guest-submit-dailog" *ngIf="displayGuestInfoForm">
  <p-dialog [(visible)]="displayGuestInfoForm" [modal]="true" [position]="dialogPosition" [dismissableMask]="true" [closable]="true"
  [draggable]=false>
    <app-guest-info [guestInfoDetailSetting]="guestInfoDetailSetting" (onClickSubmitGuestInfo)="onClickGuestSubmit($event)"></app-guest-info>
  </p-dialog>
</div>
