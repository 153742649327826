<div
  *ngIf="(isLoggedIn && !appLinkTokenResponse) || appLinkTokenResponse || (!isLoggedIn && memberDetail && !(memberDetail.memberType === 2 || memberDetail.memberType === 1) ) || !isLoading; else noLogin">
  <div class="membership-container"
    [ngStyle]="{'padding-bottom': displayGoToStore || displayViewVoucherBtn ? '100px' : '0px'}" *ngIf="memberDetail">

    <div class="back-btn" *ngIf="!isMobileView" (click)="onBackPreviousPage()">
      <i class="oda-chevron-left"></i>
      {{'button.back' | translate}}
    </div>

    <!--mobile header-->
    <div class="membership-mobile-header" *ngIf="isMobileView">
      <i class="oda-chevron-left" (click)="onBackPreviousPage()"></i>
      <h1 class="membershipDesc" *ngIf="memberDetail.membershipDesc">{{memberDetail.membershipDesc}}</h1>
      <i class="oda-tnc" (click)="onClickQuitDialog()"
        [style.visibility]="!appLinkTokenResponse ? 'visible' : 'hidden'"></i>
    </div>

    <div class="membership-content">
      <!--mobile: membershipNo && expire date-->
      <div class="mobile-membership-info" *ngIf="isMobileView">
        <div class="membershipNoAndLevelDesc">
          <h1 class="membershipNo" *ngIf="memberDetail.membershipNo">
            {{'membership.id.label' | translate}}: {{memberDetail.membershipNo}}
          </h1>
          <div class="level-desc text-truncate" *ngIf="memberDetail.levelDesc">{{memberDetail.levelDesc}}</div>
        </div>
        <h1 class="membershipExpiryDate" *ngIf="memberDetail.expiryDate">{{'membership.expire'| translate}}:
          <span>{{memberDetail.expiryDate | date: "dd/MM/yyyy"}}</span>
        </h1>
      </div>
      <div class="membership-info">
        <!--membership name && membershipNo && level && history-->
        <div class="detail" *ngIf="!isMobileView">
          <h1 class="membershipDesc">{{memberDetail.membershipDesc}}</h1>
          <div class="membershipNo" *ngIf="memberDetail.membershipNo">{{'membership.id.label' | translate}}:
            {{memberDetail.membershipNo}} <span class="level-desc" *ngIf="memberDetail.levelDesc">
              {{memberDetail.levelDesc}}
            </span></div>
          <h1 class="membershipRewardHistory" (click)="onClickHistory()"
            *ngIf="!appLinkTokenResponse && (memberDetail.memberType === 2 || memberDetail.memberType === 1)">
            {{'membership.my.reward.history.label' | translate}}</h1>
        </div>
        <!--membership term&condition && quit membership-->
        <i class="oda-tnc" (click)="onClickQuitDialog()"
          [style.visibility]="!appLinkTokenResponse ? 'visible' : 'hidden'" *ngIf="!isMobileView"></i>
        <div class="image-content" (click)="displayQrPopup()">
          <!--with card image-->
          <div class="card-image" *ngIf="memberDetail.cardImageData && this.cardImageError == false">
            <img [src]="memberDetail.cardImageData" alt="membership card" loading="lazy">
          </div>
          <!--without card image-->
          <div *ngIf="!memberDetail.cardImageData || this.cardImageError == true"
            class="logo-image">
            <img [src]="memberDetail.logoImageData" onerror="this.src='assets/image-unavailable.svg'"
              alt="membership logo" loading="lazy" />
            <h1 class="desc-heading">{{memberDetail.membershipDesc}}</h1>
          </div>
          <!--user bar code-->
          <i class="oda-qrcode"
            *ngIf="(memberDetail.cardImageData || memberDetail.logoImageData) && !appLinkTokenResponse"></i>
          <!--customer name-->
          <div class="printed-name text-truncate" *ngIf="memberDetail.printedName">
            {{memberDetail.printedName}}
          </div>
        </div>
      </div>
      <!--membership expire date-->
      <div class="expiryDate" *ngIf="!isMobileView && memberDetail.expiryDate">
        <h1>{{'membership.expire'| translate}}:
          <span>{{memberDetail.expiryDate | date: "dd/MM/yyyy"}}</span>
        </h1>
      </div>
      <!--point/stamp tab button-->
      <div class="point-stamp-tab"
      [ngClass]="{'point-stamp-bottomSpace': memberDetail.memberType == 2 || memberDetail.memberType == 1}">
        <!--point-->
        <div class="tab-button" (click)="onSwitchTab('point')"
          *ngIf="memberDetail.rewardType === checkRewardType.pointStamp || memberDetail.rewardType === checkRewardType.point">
          <h1 [ngClass]="{'active' : displayPointContent == true}" *ngIf="!isMobileView"><i
              class="oda-membership-point"></i><span>{{memberDetail.closingPoint |
              number}}</span>{{'membership.points.unit.label'
            | translate}}</h1>
          <h1 *ngIf="isMobileView" [ngClass]="{'active' : displayPointContent == true}">
            <i class="oda-membership-point"></i>{{'membership.points.unit.label' | translate}}
            <i class="oda-chevron-right" *ngIf="memberDetail.rewardType === checkRewardType.pointStamp"></i>
          </h1>
          <h1 *ngIf="isMobileView" class="mobile-point-stamp" [ngClass]="{'active' : displayPointContent == true}">
            {{memberDetail.closingPoint | number}}
          </h1>
          <p *ngIf="balanceAmount && balanceAmount !== 0"
            [innerHtml]="'membership.spend.desc' | translate: { price : '<span style=\'font-weight: bold; text-transform: uppercase;\'>' + (balanceAmount | currency: currency.currCode : 'symbol-narrow') + '</span>' } | safeHtml">
          </p>
        </div>
        <!--only show when point & stamp-->
        <div class="gap-line" *ngIf="memberDetail.rewardType === checkRewardType.pointStamp && !isMobileView">
        </div>
        <!--stamp-->
        <div class="tab-button" (click)="onSwitchTab('stamp')"
          *ngIf="memberDetail.rewardType === checkRewardType.pointStamp || memberDetail.rewardType === checkRewardType.stamp">
          <h1 [ngClass]="{'active' : displayStampContent == true}" *ngIf="!isMobileView"><i
              class="oda-membership-stamp"></i><span>{{memberDetail.stampEarned}}/{{memberDetail.totalStamp}}</span>{{'membership.stamps.unit.label'
            | translate}}</h1>
          <h1 *ngIf="isMobileView" [ngClass]="{'active' : displayStampContent == true}">
            <i class="oda-membership-stamp"></i>{{'membership.stamps.unit.label' | translate}}
            <i class="oda-chevron-right" *ngIf="memberDetail.rewardType === checkRewardType.pointStamp"></i>
          </h1>
          <h1 *ngIf="isMobileView" class="mobile-point-stamp" [ngClass]="{'active' : displayStampContent == true}">
            {{memberDetail.stampEarned}}/{{memberDetail.totalStamp}}
          </h1>
          <!--desktop view text: balance stamp to collect reward-->
          <p *ngIf="!isMobileView"
            [innerHtml]="'membership.for.reward.desc.2' | translate: { quantity: '<span style=\'font-weight: bold\'>' + balanceStamp + ' ' + ('membership.more.label' | translate) + ' ' + ('membership.stamps.unit.label' | translate) +'</span>' } | safeHtml">
          </p>
          <!--mobile view text : balance stamp to collect reward-->
          <p *ngIf="isMobileView"
            [innerHtml]="'membership.for.reward.desc' | translate: { quantity: '<span style=\'font-weight: bold\'>' + balanceStamp + ' ' + ('membership.more.label' | translate) + '+' +'</span>' } | safeHtml">
          </p>
        </div>
        <!--mobile reward history-->
        <div class="mobile-membership-reward-history" (click)="onClickHistory()"
        *ngIf="isMobileView && !appLinkTokenResponse && (memberDetail.memberType == 2 || memberDetail.memberType == 1)">
          <h1>{{'membership.my.reward.history.label' | translate}}</h1>
          <i class="oda-chevron-right"></i>
        </div>
      </div>
      <!--point content-->
      <div class="point-content" [style.padding-bottom]="displayViewVoucherBtn || displayGoToStore  ? '150px' : '50px'"
        *ngIf="this.memberVoucherList.length > 0 && displayPointContent && !appLinkTokenResponse">
        <h1>{{'membership.redemption.voucher' | translate}}</h1>
        <div *ngFor="let itm of this.memberVoucherList">
          <app-member-voucher [membershipVoucher]="itm"
            [ngClass]="{'greyOut' : itm.pointAmount > this.memberDetail.closingPoint}" (onShowVoucher)="onShowVoucher()"
            [isShowPointAmount]="true"></app-member-voucher>
        </div>
      </div>
      <!--stamp content-->
      <div class="stamp-content" *ngIf="displayStampContent">
        <app-membership-stamp [memberDetail]="memberDetail"></app-membership-stamp>
      </div>
    </div>

    <!--no ongoing loyalty program-->
    <div class="no-ongoing-loyalty-program"
      *ngIf="this.memberVoucherList.length <= 0 && this.memberDetail.stampChart && this.memberDetail.stampChart?.stampList?.length <= 0">
      <h1>{{'no-ongoing-loyalty-program' | translate}}</h1>
    </div>

    <!--go to store footer : go to store & voucher button from reward page-->
    <div class="membership-action-footer" *ngIf="displayGoToStore" [hidden]="displayMemberVoucherDialog">
      <button (click)="onShowNearbyStore();"
        *ngIf="memberDetail?.storeResponseList && memberDetail.storeResponseList.length > 0">{{'button.voucher.gotostore'
        | translate}}</button>
      <button [ngClass]="{'storeWithVoucher-btn': memberDetail?.storeResponseList && memberDetail.storeResponseList.length > 0 }" (click)="onShowClaimedVoucher()">
        <i class="oda-ticket"></i>
        <span [hidden]="memberDetail?.storeResponseList && memberDetail.storeResponseList.length > 0">{{'my.vouchers' | translate}}</span>
      </button>
    </div>
    <!--go to store footer : voucher button only from store page or storelist is empty-->
    <div class="membership-action-footer" *ngIf="displayViewVoucherBtn" [hidden]="displayMemberVoucherDialog">
      <button (click)="onShowClaimedVoucher()"><i class="oda-ticket"></i><span>{{'my.vouchers' |
          translate}}</span></button>
    </div>
  </div>
</div>

<ng-template #noLogin>
  <div class="membership-no-login-container" *ngIf="!isLoading">
    <!--mobile header-->
    <div class="membership-no-login-header" *ngIf="isMobileView">
      <i class="oda-chevron-left" routerLink="/home"></i>
      <h1>{{'MEMBERSHIP' | translate}}</h1>
    </div>
    <img class="mascot" src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot"  *ngIf="channelId | isShowMascot"/>
    <p>{{ 'membership.login.to.view' | translate }}</p>
    <button routerLink="/login" class="btn-login">{{ 'button.login' | translate }}</button>
  </div>
</ng-template>

<!-- reward history sidebar-->
<div class="membership-reward-history-sidebar">
  <p-sidebar [(visible)]="displayRewardHistory" position="right" [baseZIndex]="10000" [blockScroll]="true">
    <app-membership-reward-history *ngIf="displayRewardHistory" (clickClose)="this.displayRewardHistory = false"
      [membershipCode]="membershipCode" [channelId]="channelId">
    </app-membership-reward-history>
  </p-sidebar>
</div>

<!--quit membership term data -->
<p-dialog header="" [(visible)]="displayQuitMembershipTerm" [blockScroll]="true" [modal]="true"
  [dismissableMask]="false" class="quit-member-tnc-dialog">
  <ng-template pTemplate="header">
    <i class="oda-times" (click)="displayQuitMembershipTerm = false" *ngIf="!isMobileView"></i>
    <div class="mobile-header-quit-member" [ngClass]="{'expiry-padding-top': displayQrExpiryDate}" *ngIf="isMobileView">
      <i class="oda-chevron-left" (click)="displayQuitMembershipTerm = false"></i>
      <h1 *ngIf="displayJoin">{{'navigation.Menu.des.15' | translate}}</h1>
      <h1 *ngIf="displayQuit">{{'button.quit.membership.desc' | translate}}</h1>
    </div>
  </ng-template>

  <app-join-term-data *ngIf="displayJoin" [membershipCode]="membershipCode" [isQuit]="true"
    [externalMemberLinkMode]="externalMemberLinkMode" (continueQuit)="continueQuit()"></app-join-term-data>
  <app-quit-member-term *ngIf="displayQuit" (onContinue)="onContinue()"></app-quit-member-term>
</p-dialog>
<!--quit member-->
<p-dialog header="" [(visible)]="displayDeleteAccDialog" [position]="dialogPosition" *ngIf="displayDeleteAccDialog"
  [blockScroll]="true" [modal]="true" [dismissableMask]="false" class="quit-member-dialog">
  <app-quit-member [membershipCode]="membershipCode" (onHide)="displayDeleteAccDialog = false"
    [memberDetail]="memberDetail"></app-quit-member>
</p-dialog>

<!--show when no enought closing point to redeem voucher-->
<p-dialog header="" [(visible)]="displayRedeemError" [position]="dialogPosition" *ngIf="displayRedeemError"
  [blockScroll]="true" [modal]="true" [dismissableMask]="false" class="memberVoucher-redeem-error">
  <div class="redeemError-content">
    <img src="assets/mascot/Mascot_sad@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot">
    <h1 class="redeemError-heading-1">{{'membership.voucher.insufficient.title' | translate}}</h1>
    <h1 class="redeemError-heading-2">{{'membership.voucher.insufficient.desc' | translate: {pointRequires:
      pointRequires} }}</h1>
    <button (click)="onClickOkay()">{{'merchant.reorder.button.desc.1' | translate}}</button>
  </div>
</p-dialog>

<!--nearby store-->
<p-dialog [(visible)]="showNearbyStore" [position]="dialogPosition" [blockScroll]="true"
  [modal]=true [draggable]=false [dismissableMask]="true" class="dialog-store"
  *ngIf="showNearbyStore && storeResponseList">
  <ng-template pTemplate="header">
    <i class="oda-times" (click)="showNearbyStore = false"></i>
  </ng-template>
  <app-nearby-store [storeList]="storeResponseList" [currency]="currency" [currentCart]="cartModel">
  </app-nearby-store>
</p-dialog>

<!--claim voucher-->
<p-dialog [(visible)]="showClaimedVoucher" [position]="dialogPosition" [blockScroll]="true" [modal]=true
  [draggable]=false [dismissableMask]="true" *ngIf="showClaimedVoucher" [hidden]="displayMemberVoucherDialog" class="claimVoucher-dialog">
  <h1 class="claimedVoucher-header" *ngIf="displayClaimVoucherList || isMobileView">{{'rewards.my.voucher' | translate}}
  </h1>
  <div *ngIf="displayClaimVoucherList">
    <app-member-voucher *ngFor="let itm of claimedVoucherList" [membershipVoucher]="itm" [isShowPointAmount]="false"
      (onShowVoucher)="onShowClaimedVoucherDetail()"></app-member-voucher>
  </div>

  <div class="emptyClaimedVoucher" *ngIf="displayEmptyList">
    <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" *ngIf="channelId | isShowMascot">
    <h1>{{'rewards.result.empty.des.1' | translate}}</h1>
    <p>{{'membership.voucher.will.show.once.claimed' | translate}}</p>
  </div>
</p-dialog>

<!--available voucher detail-->
<p-dialog header="" [(visible)]="displayMemberVoucherDialog" *ngIf="displayMemberVoucherDialog" [blockScroll]="true"
  [modal]="true" [dismissableMask]="false" class="membership-voucher-detail-dialog">
  <app-membership-voucher-detail [membershipVoucherDetail]="voucherDetail"
    [ngClass]="{'button-greyOut' : voucherDetail.pointAmount > this.memberDetail.closingPoint, 'expiry-membership': displayQrExpiryDate}"
    (onClickClaim)="onClaim()" (onClose)="this.displayMemberVoucherDialog = false"
    [onshowInfo]="onshowInfo"></app-membership-voucher-detail>
</p-dialog>
