import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-countdown',
  templateUrl: './order-countdown.component.html',
  styleUrls: ['./order-countdown.component.scss']
})
export class OrderCountdownComponent implements OnInit {
  @Input() expiryTime!: string; // Input: Server-provided expiry time (e.g., "2025-01-23T17:30:00")
  @Input() serverTime!: string; // Input: Server-provided current time (e.g., "2025-01-23T17:20:00")

  @Output() onShowExpiryDate = new EventEmitter<boolean>();
  @Output() onTimeup = new EventEmitter<boolean>();

  showTimer: boolean = false;
  isAlert: boolean = false;
  minutes: string = '00';
  seconds: string = '00';
  private expiryTimestamp!: number; // Expiry time converted to UTC timestamp
  private currentServerTimestamp!: number; // Server time in UTC timestamp
  private interval: any;

  ngOnInit() {
    this.initializeTimer();
  }

  initializeTimer() {
    // Convert expiryTime (local time) to UTC
    const localExpiryTime = new Date(this.expiryTime);
    this.expiryTimestamp = localExpiryTime.getTime();

    // Parse serverTime (already in UTC)
    this.currentServerTimestamp = new Date(this.serverTime).getTime();

    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      // Calculate the remaining time based on the actual current time
      const now = new Date().getTime();
      const timeElapsedSinceServer = now - this.currentServerTimestamp;
      const adjustedCurrentTime = this.currentServerTimestamp + timeElapsedSinceServer;

      const remainingTime = Math.max(this.expiryTimestamp - adjustedCurrentTime, 0);

      if (remainingTime > 0) {
        const totalSeconds = Math.floor(remainingTime / 1000); // Convert ms to seconds
        this.minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
        this.seconds = (totalSeconds % 60).toString().padStart(2, '0');

        // Show only if <= 10 minutes
        if (totalSeconds <= environment.qrCountdownSec) {
          this.showTimer = true;
          this.onShowExpiryDate.emit(true);
        }

        // Red background if <= 1 minute
        this.isAlert = totalSeconds <= environment.qrCountdownAlertSec;
      } else {
        clearInterval(this.interval); // Stop timer if time is up
        this.showTimer = false;
        this.onTimeup.emit(true);
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
