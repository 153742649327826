import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-apply-promo',
  templateUrl: './apply-promo.component.html',
  styleUrls: ['./apply-promo.component.scss']
})
export class ApplyPromoComponent implements OnInit {
  @Input('searchPromoCode') searchPromoCode: string;
  @Input('invalidPromo') invalidPromo: boolean;
  @Input ('invalidPromoMsg') invalidPromoMsg : string ;
  @Output() onApplyPromo = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  onClickApplyPromo() {
    this.onApplyPromo.emit(this.searchPromoCode);
  }

  onClickClose() {
    this.searchPromoCode = "";
    this.invalidPromo = false;
  }

}
