import { PageName } from './core/enums/PageName';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { StoreService } from 'src/app/store/store/store.service';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateCacheService } from 'ngx-translate-cache';
import { UserChnlService } from '../app/home/userchnl/userchnl.service';
import { ChannelService } from './home/channel/channel.service';
import { CXMCustomerAddressResponse } from './core/models/CXMCustomerAddressResponse';
import { UserService } from './core/user/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StoreMode } from './core/enums';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LoaderService } from './core/loader-services/loader.service';
import { PopupAddressFormService } from './core/services/popup-address-form.service';
import { CommonAddressResponse } from './core/models/CommonAddressResponse';
import { QrCartStore } from './core/qr-cart/qr-cart.store';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { QrCartService } from './core/qr-cart/qr-cart.service';
import { ToastData } from './core/models/ToastData';
import { ToastService } from './shared/services/toast.service';
import { ChannelSecService } from './home/channelsec/channelsec.service';
import { OrderService } from './home/order/order.service';
import { PreviousRoute } from './core/models/PreviousRoute';
import { RouteName } from './core/enums/RouteName';
import { PreviousRouteService } from './core/services/previous-route.service';
import { CartService } from './core/cart/cart.service';
import { PlatformSet } from './core/models';
import { ChannelQuery } from './home/channel/channel.query';
import { OrderTypeFlag } from './core/enums/OrderTypeFlag';
import { StaticQrService } from './core/static-qr/static-qr.service';
import { QrScannerService } from './core/services/qr-scanner.service';
import { SetCode } from './core/enums/SetCode';
import { TimeService } from './core/services/time.service';
import { ActivitySummary } from './home/order/order.model';
import * as moment from 'moment';
import { decodeBase64 } from './core/services/utils.service';
import { RoutingService } from './home/services/routing.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { AnalyticsService } from './shared/services/analytics.service';
import { StorageService } from './shared/services/storage.service';
import { SessionStorageService } from './shared/storage/session-storage.service';
import { SeoService } from 'src/app/shared/services/seo.service';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ThemeService} from 'src/app/shared/services/theme.service';
import { QrCartQuery } from './core/qr-cart/qr-cart.query';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'odaringWeb';
  showHeader = false;
  showFooter = false;

  displayAddressDialog: boolean = false;
  displayOrderTypeAdressDialog: boolean = false;

  displayAddress = false;
  displayAddAddress = false;
  savedAddress: CXMCustomerAddressResponse[] = [];
  editUserAddress: CXMCustomerAddressResponse;
  updateAddressSuccess: boolean = false;
  isUserLogin: boolean = false;
  sub: Subscription = new Subscription();
  channelSub: Subscription = new Subscription();
  triggerChannelData$: Subject<boolean> = new Subject<boolean>();
  loadingSubscription: Subscription;
  isFinishedLoading: boolean = false;
  curRouter: string = '';
  showMobileHeader: boolean = false;
  showMobileBottomNav: boolean = false;
  localSavedNewAddress: CommonAddressResponse;
  qrTokenResponse: WebLinkTokenResponse;
  displayQuitQrDineMessage: boolean;
  quitQrDineInSub: Subscription;
  isLogOut: boolean;
  logOutSub: Subscription;
  displaySessionExpiredMessage: boolean = false;
  sessionExpired: boolean = false;
  sessionExpiredSub: Subscription;
  quitQueueSub$: Subscription;
  channel$: Subscription;

  //dialog
  dialogPosition: string;
  dismissable: boolean;
  isMobileView: boolean;
  modelViewerData: string = '';
  channelSetting: PlatformSet[] = [];
  shareTable: boolean = false;
  dialogClassName: string = 'home-address-dialog';
  channelOrderType: string = '';
  orderTypeFlag = OrderTypeFlag;
  displayScanner: boolean = false;
  qrScannerData: any;
  displayQuitQueueMessage: boolean;

  displayQrPopup: boolean = false;
  qrScannerSub$: Subscription;
  fulladdress: string;
  channelId: number;

  qrQuerySub$ : Subscription;
  qrExpiryDate: string = null;
  timeInSec: BehaviorSubject<number> = new BehaviorSubject<number>(1800);

  constructor(
    public translate: TranslateService,
    public translateCacheService: TranslateCacheService,
    private userchnService: UserChnlService,
    private channelService: ChannelService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingScreenService: LoaderService,
    private storeService: StoreService,
    private popupAddressFormService: PopupAddressFormService,
    private qrCartStore: QrCartStore,
    private breakpointObserver: BreakpointObserver,
    private qrCartService: QrCartService,
    private toastService: ToastService,
    private channelSecService: ChannelSecService,
    private orderService: OrderService,
    private previousRouteService: PreviousRouteService,
    private cartService: CartService,
    private channelQuery: ChannelQuery,
    private staticQrService: StaticQrService,
    private qrScannerService: QrScannerService,
    private routingService: RoutingService,
    private timeService: TimeService,
    private queueService: QueueService,
    private userQuery: UserQuery,
    private analyticsService: AnalyticsService,
    private storageService: StorageService,
    private sessionStorageService: SessionStorageService,
    private seoService: SeoService,
    private platformLocation: PlatformLocation,
    @Inject(DOCUMENT) private document: any,
    private themeService : ThemeService,
    private qrCartQuery : QrCartQuery,
  ) {

    translate.setDefaultLang('en');

    // The order of precedence
    // 1. Cached language.
    // 2. Current language of the browser.
    // 3. Default language (language used as a fallback for *ngx-translate*).
    translateCacheService.init();

    //updating seo
    this.updateMetaTag(this.document.location.href);

    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {

        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader === true;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter === true;

        if (this.activatedRoute.firstChild.snapshot.firstChild && this.activatedRoute.firstChild.snapshot.firstChild?.data?.showFooter) {
          this.showFooter = true;
        }

        let currentUrlPath1 = event.url.split("/")[1];

        if (event.url && currentUrlPath1) {
          let currentUrl = event.url;

          if (!currentUrl.includes('/login') && !currentUrl.includes('/password-login') && !currentUrl.includes('/otp')
            && !currentUrl.includes('/forgot-password') && !currentUrl.includes('/account-detected')) {
            this.sessionStorageService.removeItem("navigateToPayment");
          }

          if (currentUrl.includes('/home')) {
            this.showMobileHeader = true;
          } else {
            this.showMobileHeader = false;
          }

          if (currentUrl.includes('/home') || currentUrl.includes('/profiles') ||
            currentUrl.includes('/reward') || currentUrl.includes('/reward/explore') || currentUrl.includes('/reward/history') ||
            currentUrl.includes('/my-order') || currentUrl.includes('/my-order/active') || currentUrl.includes('/my-order/history')) {
            this.showMobileBottomNav = true;
          } else {
            this.showMobileBottomNav = false;
          }

          if (currentUrlPath1.includes('home') || currentUrl.includes('/promotion-details-page')) {
            this.curRouter = 'home';
          } else if (currentUrlPath1.includes('store')) {
            this.curRouter = 'store';
          } else {
            this.curRouter = '';
          }
        } else {
          this.showMobileBottomNav = false;
        }

        let isBlockBack = this.sessionStorageService.getItem('isBlockBack');
        if (isBlockBack && !currentUrlPath1.includes('store')) {
          this.sessionStorageService.removeItem('isBlockBack');
        }
      }
    });

    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.dialogPosition = "bottom";
        this.dismissable = true;
        this.isMobileView = true;
      }
      else {
        this.dialogPosition = "center";
        this.dismissable = false;
        this.isMobileView = false;
      }
    });

  }

  async ngOnInit() {
    await this.userchnService.checkUpdateDomainFlag();
    this.cartService.removePreviousFormatCart();

    this.loadingSubscription = this.loadingScreenService.loading$.pipe().subscribe((status: boolean) => {
      this.isFinishedLoading = !status;
    });

    this.sub = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      if (!this.isUserLogin && userData) {
        this.channelSecService.removeAll();
      }

      if (userData && userData.name === '') {
        this.userService.clearUser();
      }

      this.isUserLogin = userData ? true : false;
    });

    this.storeService.triggeredAddressDialog$.subscribe(val => {
      if (val) {
        this.onClickLocation();
      }
      else {
        this.closeDialog();
      }
    });

    // check address
    this.popupAddressFormService.popupAddressForm.subscribe((result) => {
      if (result) {
        this.localSavedNewAddress = JSON.parse(localStorage.getItem('recent-address'));
        this.displayAddressDialog = true;
        this.displayAddAddress = true;
        localStorage.removeItem('showAddressForm');
      }
    });

    this.qrTokenResponse = JSON.parse(this.sessionStorageService.getItem("qrTokenResponse"));
    if (!this.qrTokenResponse) {
      this.qrCartStore.update({ qrTokenResponse: null });
    }

    this.quitQrDineInSub = this.storeService.toQuitQrDineIn$.subscribe(toDisplay => {
      this.displayQuitQrDineMessage = toDisplay;
    });

    this.quitQueueSub$ = this.queueService.toQuitQueue$.subscribe(toQuitQueue => {
      this.displayQuitQueueMessage = toQuitQueue;
    });

    this.logOutSub = this.userService.isLoggedOut$.subscribe(toLogOut => {
      this.isLogOut = toLogOut;
    });

    this.logOutSub = this.userService.displayQrPopup$.subscribe(displayQrPopup => {
      this.displayQrPopup = displayQrPopup;
    });

    this.sessionExpiredSub = this.orderService.tokenExpired$.subscribe(sessionExpired => {
      if (sessionExpired) {
        this.displaySessionExpiredMessage = true;
        this.sessionExpired = true;
      }
    });

    this.channelSub = this.channelQuery.currentChannel$.subscribe(async (channel) => {
      this.channelOrderType = channel?.orderType;
      const orderTypeCount = await this.channelService.getOrderTypeCount(channel && channel?.data ? channel.data : null);

      // if channel order type count == 1 then get the only order type available
      if (orderTypeCount == 1) {
        this.channelOrderType = await this.channelService.getOrderTypeWhenOnlyOneType(channel.data);
      }

      this.channelSetting = channel?.data?.platformSets ? channel?.data?.platformSets : [];
      let foundSetting = this.channelSetting.find(setting => setting.setCode === SetCode.SHARETABLE)?.setValue;
      this.shareTable = foundSetting && foundSetting == '1' ? true : false;

      this.dialogClassName = this.shareTable && this.channelOrderType === this.orderTypeFlag.DineIn ? 'home-location-dialog' : 'home-address-dialog';

      this.themeService.loadTheme(channel?.data?.channelTag ?? null );

      this.channelId = channel?.data.channelId ?? null;
    });

    this.qrScannerSub$ = this.qrScannerService.showPopupScanner$.subscribe((qrScannerData: any) => {
      this.displayScanner = qrScannerData.isShow;
      this.qrScannerData = qrScannerData;
    });

    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let userState = event.storageArea.getItem('odaring');

        if (userState) {
          let userStateObj = JSON.parse(userState);

          if (!userStateObj['user']?.active && this.userService.getCustomer()) {
            this.userService.logout();
          }
        }
      }
    }, false);

    // check and remove the guestOrder cache data
    this.removeExpiredGuestOrder();

    //curent selected address
    this.userQuery.selectedAddress$.subscribe(address => {
      if (address) {
        this.fulladdress = address.fullAddress;
      }
    });

    this.qrQuerySub$ = this.qrCartQuery.qrWebLinkResponse$.subscribe(webLinkData => {
      if (webLinkData) {
        this.qrExpiryDate = webLinkData?.expiryDate;
      }
    })
  }

  // using
  async onOrderTypeChanged(selectedOrderType: string) {
    await this.channelService.getChannel({ orderType: selectedOrderType, channelTag: this.channelService.getChannelData().channelTag });
  }

  mobileOrderTypeChanged(selectedOrderType: string) {
    this.channelService.setCurrentOrderType(selectedOrderType);
  }

  async mobileOrderTypeDoneAction() {
    this.displayOrderTypeAdressDialog = false;
    let orderType = this.channelService.getCurrentOrderType();
    this.staticQrService.saveChannelOrderType(orderType);
    this.analyticsService.selectDeliveryModeEvent(orderType);

    await this.channelService.getChannel({ orderType: orderType, channelTag: this.channelService.getChannelData().channelTag });
  }

  async onChangeChannel(channelTag: string) {
    await this.channelService.getChannel({ channelTag: channelTag });
  }

  async onClickLocation() {
    if (this.isUserLogin) {
      this.getCustomerAddress();
    } else {
      this.displayAddAddress = false;
      this.displayAddress = true;
      this.displayAddressDialog = true;
    }
  }

  async onClickOrderTypeLocation() {
    this.displayOrderTypeAdressDialog = true;
  }

  async onSubmitAddAddress(data: any) {
    let resp = await this.userService.registerCustomerAddress(data);

    if (!(resp instanceof HttpErrorResponse) && this.isMobileView) {
      let toastData = {} as ToastData;
      toastData.icon = "oda-check";
      toastData.iconCircleColor = "#8CD600";
      toastData.iconColor = "#FFFFFF";
      toastData.showCircleIcon = true;
      toastData.message = "add.addess.success.toast.1";
      this.toastService.show(toastData);
    }
    await this.getCustomerAddress();
  }

  async onUpdateAddress(data: any) {
    await this.userService.updateCustomerAddress(data);
    this.updateAddressSuccess = true;
    await this.getCustomerAddress();
  }

  async deleteSelectedAddress(addressId: number) {
    await this.userService.deleteCustomerAddress(addressId, this.channelService.getChannelId());
    await this.getCustomerAddress();
  }

  async getCustomerAddress() {
    this.savedAddress = await this.userService.getCustomerAddress(this.channelService.getChannelId());
    this.displayAddAddress = false;
    this.displayAddress = true;
    this.displayAddressDialog = true;
  }

  displayAddAddressForm(savedNewAddress: CommonAddressResponse) {
    this.editUserAddress = null;
    this.displayAddress = false;
    this.displayAddAddress = true;
    this.displayAddressDialog = true;
    this.updateAddressSuccess = false;

    if (savedNewAddress) {
      this.localSavedNewAddress = savedNewAddress;
    }
  }

  onClickCloseDialog() {
    if (this.displayAddress) {
      this.storeService.openOutOfCoverage$.next(true);
    }
    this.closeDialog();
  }

  closeDialog() {
    if (this.displayAddress) {
      this.displayAddress = false;
      this.displayAddAddress = false;
      this.displayAddressDialog = false;
      this.displayOrderTypeAdressDialog = false;
    } else if (this.displayAddAddress) {
      this.displayAddress = true;
      this.displayAddAddress = false;
      this.displayAddressDialog = true;
    }
  }

  editSelectedAddress(address: CXMCustomerAddressResponse) {
    this.displayAddAddress = true;
    this.displayAddress = false;
    this.displayAddressDialog = true;
    this.editUserAddress = address;
  }

  //#region quit qr dine in
  closeQuitQrDialog() {
    this.displayQuitQrDineMessage = false;
    this.storeService.closeQuitQrDialog();
  }

  quitQrDineInMode() {
    let channelData = this.channelService.getChannelData();
    this.closeQuitQrDialog();

    this.storageService.resetQrExpiryTime();
    this.timeService.removeIdleTime();
    this.qrCartService.quitQrDineInCheck();
    this.staticQrService.remove();

    if (this.isLogOut) {
      this.userService.logoutInit(false);
      this.channelService.getChannel();
    }
    else {
      this.routingService.navigate(PageName.HomePage, channelData);
    }
  }
  //#endregion

  closeSessionExpiredPopup() {
    this.displaySessionExpiredMessage = false;
  }

  async navigateToLogin() {
    this.qrTokenResponse = JSON.parse(this.sessionStorageService.getItem("qrTokenResponse"));

    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];
    let previousRoute = {} as PreviousRoute;

    this.userService.logout();
    if (currentRoute == RouteName.OrderPayment && this.qrTokenResponse) {
      previousRoute.routeName = RouteName.Home;
      this.qrCartService.quitQrDineInCheck();
    }
    else if (currentRoute == RouteName.OrderPayment && !this.qrTokenResponse) {
      previousRoute.routeName = RouteName.Cart;
    }
    else {
      previousRoute.routeName = this.router.url;
    }

    this.previousRouteService.savedPreviousRoute(previousRoute);

    this.router.navigate(['login']);
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
    this.quitQrDineInSub?.unsubscribe();
    this.logOutSub?.unsubscribe();
    this.sessionExpiredSub?.unsubscribe();
    this.sub?.unsubscribe();
    this.channelSub?.unsubscribe();
    this.quitQueueSub$?.unsubscribe();
    this.qrQuerySub$?.unsubscribe();
  }

  removeExpiredGuestOrder() {
    let guestOrder = localStorage.getItem('guest-order');
    let decodedGuestOrder = guestOrder ? decodeBase64(guestOrder) : null;
    let cacheGuestOrder: ActivitySummary = decodedGuestOrder ? JSON.parse(decodedGuestOrder) : null;

    if (cacheGuestOrder) {
      let localTime = this.timeService.getAdjustedLocalTimeInUtc();
      let tranUtcDate = this.timeService.convertToUtc(moment(cacheGuestOrder.tranDate));
      let timeIsAfter = this.timeService.compareIsTimeAfter(localTime.toString(), tranUtcDate.toString(), 3, 'days');

      if (timeIsAfter) {
        localStorage.removeItem('guest-order');
      }
    }
  }

  //#region queue quit logic
  closeQuitQueueDialog() {
    this.displayQuitQueueMessage = false;
    this.queueService.closeQuitQueuePopup();
  }

  async quitQueueMode() {
    let channelData = this.channelService.getChannelData();
    let switchChannelData = this.channelService.getSwitchChannelData();

    this.closeQuitQueueDialog();
    this.queueService.removeQueueData();
    this.queueService.removeShowBackIcon();
    this.queueService.removeActivityRsvToken();

    if (this.isLogOut) {
      this.userService.logoutInit(false);
      this.channelService.getChannel();
    }
    else {
      if (channelData) {
        this.routingService.navigate(PageName.HomePage, channelData);
      }
      else if (switchChannelData) {
        this.routingService.navigateHomeCheck(switchChannelData);
      }
      else {
        switchChannelData = await this.channelService.switchChannelVerify(false);
        this.channelService.setSwitchChannelData(switchChannelData);
        this.routingService.navigateHomeCheck(switchChannelData);
      }
    }
  }

  //#endregion

  updateMetaTag(url: any) {
    let href = this.platformLocation.href;
    let path = href.split(/https?:\/\//).map(part => part.split('.'))[1][0];

    if (path.includes(environment.hostName) || path.includes('localhost')) {
      this.seoService.manageMetaTag(url);
    }
    else {
      this.seoService.manageMetaTag(url, path);
    }
  }
}
