export enum SetCode{
  DELINTERVALMIN = "DELINTERVALMIN",
  PICKINTERVALMIN = "PICKINTERVALMIN",
  REFNOLABEL = "REFNOLABEL",
  ASKREFNO = "ASKREFNO",
  DIREQTBLNO = "DI_REQTBLNO",
  DITBLNOLBL = "DI_TBLNOLBL",
  DPPARENTIMG = "DPPARENTIMG",
  FILTERCOLORSET = "FILTERCOLORSET",
  STOREMENULAYOUT = "STOREMENULAYOUT",
  KTIPREPMINS = "KTIPREPMINS",
  SHARETABLE = "SHARETABLE",
  WHATAPP_BTN = "WHATAPP_BTN",
  DYNAREQTEL = "DYNAREQTEL",
  STATICREQTEL = "STATICREQTEL",
  STO_ODR_REMARK = "STO_ODR_REMARK",
  STO_ODR_RMK_TTL = "STO_ODR_RMK_TTL",
  C_WHATAPP_BTN = "C_WHATAPP_BTN",
  HIDEBLOCKEDITEM = "HIDEBLOCKEDITEM",
  HIDEASAP = "HIDEASAP",
  SHOWQRORDERNO = "SHOWQRORDERNO",
  CUTLERYLABEL = "CUTLERYLABEL",
  STATIC_ENASTOBACKBTN = "STATIC_ENASTOBACKBTN",
  DYNA_ENASTOBACKBTN = "DYNA_ENASTOBACKBTN",
  OT_DINE_LBL = "OT_DINE_LBL",
  OT_PICKUP_LBL = "OT_PICKUP_LBL",
  OT_TakeAway_LBL = "OT_TakeAway_LBL",
  HIDEODRREMARK = "HIDEODRREMARK",
  QRORDERNOMSG = "QRORDERNOMSG",
  DI_NOREQLOGIN = "DI_NOREQLOGIN",
  PI_NOREQLOGIN = "PI_NOREQLOGIN",
  DE_NOREQLOGIN = "DE_NOREQLOGIN",
  QUE_DISABLE_ORDER = "QUE_DISABLE_ORDER",
  ShowOrderTypePopup = "ShowOrderTypePopup",
  CAPCUSTINFO = "CAPCUSTINFO",
  CUSTREQINFO = "CUSTREQINFO"
}
