import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { SliderComponent } from './components/slider/slider.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { DownloadAppComponent } from './components/header/download-app/download-app.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LocationComponent } from './components/location/location.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { BadgeModule } from 'primeng/badge';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AccordionModule } from 'primeng/accordion';
import { TimelineModule } from 'primeng/timeline';
import { PaginatorModule } from 'primeng/paginator';
import { SkeletonModule } from 'primeng/skeleton';
import {ProgressBarModule} from 'primeng/progressbar';

import { ResetPassFormComponent } from '../account/forgot-password/reset-pass-form/reset-pass-form.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TimerComponent } from './components/timer/timer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpFormComponent } from './../account/otp/otp-form/otp-form.component';
import { AddressComponent } from './components/address/address.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { OrderTypeComponent } from './components/order-type/order-type.component';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from "swiper/angular";
import { FilterQtyPipe } from '../home/pipes/filter-qty.pipe';

import { HeaderComponent } from './components/header/header.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { FormDirective } from './../core/directive/form.directive';
import {InputTrimDirective} from './../core/directive/input-trim.directive'

import { LandingPagesComponent } from './components/landing-pages/landing-pages.component';
import { UserMenuDropdownComponent } from './components/user-menu-dropdown/user-menu-dropdown.component';
import { OrderQuickAccessSliderComponent } from './components/order-quick-access-slider/order-quick-access-slider.component';
import { PopoularOrderSliderComponent } from './components/popoular-order-slider/popoular-order-slider.component';
import { AddressListComponent } from './components/address-list/address-list.component';
import { OrderItemComponent } from '../home/payment-summary-order/order-item/order-item.component';
import { OrderAmountComponent } from '../home/payment-summary-order/order-amount/order-amount.component';
import { LostNetworkConnectionComponent } from './components/lost-network-connection/lost-network-connection.component';
import { TechnicalErrorComponent } from './components/technical-error/technical-error.component';
import { JsonParsePipe } from '../home/pipes/json-parse.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { ItemCardComponent } from './components/item-card/item-card.component';
import { PopupMessageComponent } from './components/popup-message/popup-message.component';

import { StoreOdrTimeComponent } from '../store/store-odr-time/store-odr-time.component';
import { CartFormComponent } from './components/cart-form/cart-form.component';
import { StoreItemModifierComponent } from '../store/store-item-modifier/store-item-modifier.component';
import { StoreItemContentComponent } from '../store/store-item-content/store-item-content.component';
import { StoreItemListComponent } from '../store/store-item-content/store-item-list/store-item-list.component';
import { StoreItemSubmodifierComponent } from '../store/store-item-submodifier/store-item-submodifier.component';
import { StoreItemDescriptionComponent } from '../store/store-item-modifier/store-item-description/store-item-description.component';
import { MobileFloatingCartComponent } from './components/mobile-floating-cart/mobile-floating-cart.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { EmptyDishMessageComponent } from './components/empty-dish-message/empty-dish-message.component';
import { HomeUserMessageComponent } from './components/home-user-message/home-user-message.component';
import { EmptyAddressMessageComponent } from './components/empty-address-message/empty-address-message.component';
import { OutOfServiceMessageComponent } from './components/out-of-service-message/out-of-service-message.component';
import { SocialShareComponent } from './components/social-share/social-share.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxCopyToClipboardModule } from 'ngx-copy-to-clipboard';
import { FaqComponent } from './components/faq/faq.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermNconditionsComponent } from './components/term-nconditions/term-nconditions.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { SearchNoResultComponent } from './components/search-no-result/search-no-result.component';
import { ActivityTimelineComponent } from './components/activity-timeline/activity-timeline.component';
import { StoreNotFoundErrorComponent } from './components/store-not-found-error/store-not-found-error.component';
import { ProfileNavMobileComponent } from './components/profile-nav-mobile/profile-nav-mobile.component';
import { RemoveAllMessageComponent } from './components/remove-all-message/remove-all-message.component';
import { OrderTypeIconPipe } from './pipes/order-type-icon.pipe';
import { DateTimeSelectionComponent } from './components/date-time-selection/date-time-selection.component';
import { CountdownModule } from 'ngx-countdown';
import { MobileItemCardChannelStoresComponent } from './components/mobile-item-card-channel-stores/mobile-item-card-channel-stores.component';
import { MobileItemCardPredefinedStoresComponent } from './components/mobile-item-card-predefined-stores/mobile-item-card-predefined-stores.component';
import { ActivityTimelineIconPipe } from './pipes/activity-timeline-icon.pipe';
import { ItemCardVComponent } from './components/item-card-v/item-card-v.component';
import { ItemCardHComponent } from './components/item-card-h/item-card-h.component';
import { MascotLoadingComponent } from './components/mascot-loading/mascot-loading.component';
import { InvalidQrComponent } from './components/invalid-qr/invalid-qr.component';
import { DineInInfoComponent } from './components/dine-in-info/dine-in-info.component';
import { QrDineinSelectionComponent } from './components/qr-dinein-selection/qr-dinein-selection.component';
import { CartSubmittedOrdersComponent } from './components/cart-submitted-orders/cart-submitted-orders.component';
import { QrCloseMessageComponent } from './components/qr-close-message/qr-close-message.component';
import { QrQuitMessageComponent } from './components/qr-quit-message/qr-quit-message.component';
import { NearbyStoreComponent } from './components/nearby-store/nearby-store.component';
import { LoginMessageComponent } from './components/login-message/login-message.component';
import { ToastComponent } from './components/toast/toast.component';
import { HighlightSearchTextPipe } from './pipes/highlight-search-text.pipe';
import { ModifierPricePipe } from './pipes/modifier-price.pipe';
import { RemoveSelectedItemMessageComponent } from './components/remove-selected-item-message/remove-selected-item-message.component';
import { FormatSubmodifiersPipe } from './pipes/format-submodifiers.pipe';
import { ModQuantityConditionPipe } from './pipes/mod-quantity-condition.pipe';
import { LanguageComponent } from './components/language/language.component';
import { BarcodeGeneratorAllModule, DataMatrixGeneratorAllModule, QRCodeGeneratorAllModule } from '@syncfusion/ej2-angular-barcode-generator';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FormatTimePipe } from '../shared/pipes/format-time.pipe';
import { PreorderTagComponent } from './components/preorder-tag/preorder-tag.component';
import { StringSplitPipe } from './pipes/string-split.pipe';
import { MenutagIconPipe } from './pipes/menutag-icon.pipe';
import { SkeletonPlaceholderComponent } from './components/skeleton-placeholder/skeleton-placeholder.component';
import { StoreImagePipe } from './pipes/store-image.pipe';
import { SettingsComponent } from '../account/settings/settings/settings.component';
import { DeleteAccTncComponent } from './components/delete-acc-tnc/delete-acc-tnc/delete-acc-tnc.component';
import { DeleteAccComponent } from './components/delete-acc/delete-acc/delete-acc.component';
import { DeleteAccSuccessComponent } from './components/delete-acc-success/delete-acc-success/delete-acc-success.component';
import { QrMobileDetectedComponent } from './components/qr-mobile-detected/qr-mobile-detected.component';
import { SubmitPhoneNoComponent } from './components/submit-phone-no/submit-phone-no.component';
import { NewsComponent } from './components/news/news.component';
import { BannerComponent } from 'src/app/shared/components/banner/banner.component';
import { NewsListComponent } from './components/news-list/news-list.component'
import { ChannelNotFoundComponent } from './components/channel-not-found/channel-not-found.component';
import { UserQrCodeComponent } from 'src/app/shared/components/user-qr-code/user-qr-code.component';
import { PlatformSetCPSRPipe } from './pipes/platform-set.pipe';
import { VoidItemMessageComponent } from './components/void-item-message/void-item-message.component';
import { OrderPaidMessageComponent } from './components/order-paid-message/order-paid-message.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { LocationSearchComponent } from './components/location-search/location-search.component';
import { TableNoRequiredComponent } from './components/table-no-required/table-no-required.component';
import { AlcoholConsentMessageComponent } from './components/alcohol-consent-message/alcohol-consent-message.component';
import { OtpToastComponent } from './components/otp-toast/otp-toast.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MenuVariationComponent } from './components/menu-variation/menu-variation.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { SortComponent } from './components/sort/sort.component';
import { EmptyFilterMessageComponent } from './components/empty-filter-message/empty-filter-message.component';
import { SubmittingOrderComponent } from './components/submitting-order/submitting-order.component';
import { EditPhoneNumberComponent } from './components/edit-phone-number/edit-phone-number.component';
import { CaptureEmailComponent } from './components/capture-email/capture-email.component';
import { ModGroupProcessPipe } from './pipes/mod-group-process.pipe';
import { ItemTotalPricePipe } from './pipes/item-total-price.pipe';
import { QueueInfoComponent } from './components/queue-info/queue-info.component';
import { QuitQueueMessageComponent } from './components/quit-queue-message/quit-queue-message.component';
import { QueueRemoveCartComponent } from './components/queue-remove-cart/queue-remove-cart.component';
import { ConfirmedErrorComponent } from './components/confirmed-error/confirmed-error.component';
import { RegisterFailedComponent } from './components/register-failed/register-failed.component';
import { TelInputCountryCodePipe } from './pipes/tel-input-country-code.pipe';
import { DashedLineComponent } from './components/dashed-line/dashed-line.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { AppliedPromoVoucherComponent } from './components/applied-promo-voucher/applied-promo-voucher.component';
import { CalPriceDiffPipe } from './pipes/cal-price-diff.pipe';
import { NewsDetailComponent } from './components/news-detail/news-detail.component';
import { ItemUnavailableMessageComponent } from './components/item-unavailable-message/item-unavailable-message.component';
import { PlatformSetCSPSRPipe } from './pipes/platform-set-cspsr.pipe';
import { LanguageDescPipe } from './pipes/language-desc.pipe';
import { EvenCenterGiftPipe } from './pipes/even-center-gift.pipe';
import { MiniProgramInfoComponent } from './components/mini-program-info/mini-program-info.component';
import { OverflowFadeDirective } from './directives/overflow-fade.directive';
import { StoreImageHeightDirective } from './directives/store-image-height.directive';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { SplashCarouselComponent } from './components/splash-carousel/splash-carousel.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NextAvailableTimePipe } from './pipes/next-available-time.pipe';
import { IsTodayDatePipe } from './pipes/is-today-date.pipe';
import { QueueImagePipe } from './pipes/queue-image.pipe';
import { PaymentMethodDescPipe } from './pipes/payment-method-desc.pipe';
import { BottomOdaringBackComponent } from './components/bottom-odaring-back/bottom-odaring-back.component';
import { StoreMenuTagComponent } from '../store/store-menu-tag/store-menu-tag.component';
import { MenuTagsPipe } from '../store/pipes/menu-tags.pipe';
import { FormatInnerHtmlPipe } from '../store/pipes/format-inner-html.pipe';
import { IsShowMascotPipe } from './pipes/is-show-mascot.pipe';
import { GuestInfoComponent } from './components/guest-info/guest-info.component';
import { OrderCountdownComponent } from './components/order-countdown/order-countdown.component'

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    BottomNavComponent,
    PageNotFoundComponent,
    DownloadAppComponent,
    ToolbarComponent,
    TimerComponent,
    ResetPassFormComponent,
    OtpFormComponent,
    LocationComponent,
    AddressComponent,
    OrderTypeComponent,
    FilterQtyPipe,
    BackButtonComponent,
    AddressFormComponent,
    AddressSearchComponent,
    FormDirective,
    InputTrimDirective,
    LandingPagesComponent,
    UserMenuDropdownComponent,
    OrderQuickAccessSliderComponent,
    PopoularOrderSliderComponent,
    AddressListComponent,
    OrderItemComponent,
    OrderAmountComponent,
    LostNetworkConnectionComponent,
    TechnicalErrorComponent,
    LoaderComponent,
    JsonParsePipe,
    ItemCardComponent,
    PopupMessageComponent,
    StoreOdrTimeComponent,
    CartFormComponent,
    StoreItemContentComponent,
    StoreItemListComponent,
    StoreItemModifierComponent,
    StoreItemDescriptionComponent,
    StoreItemSubmodifierComponent,
    MobileFloatingCartComponent,
    EmptyDishMessageComponent,
    HomeUserMessageComponent,
    EmptyAddressMessageComponent,
    OutOfServiceMessageComponent,
    SocialShareComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermNconditionsComponent,
    TooltipComponent,
    SearchNoResultComponent,
    ActivityTimelineComponent,
    StoreNotFoundErrorComponent,
    ProfileNavMobileComponent,
    RemoveAllMessageComponent,
    OrderTypeIconPipe,
    DateTimeSelectionComponent,
    MobileItemCardChannelStoresComponent,
    MobileItemCardPredefinedStoresComponent,
    ActivityTimelineIconPipe,
    ItemCardVComponent,
    ItemCardHComponent,
    MascotLoadingComponent,
    InvalidQrComponent,
    DineInInfoComponent,
    QrDineinSelectionComponent,
    CartSubmittedOrdersComponent,
    QrCloseMessageComponent,
    QrQuitMessageComponent,
    NearbyStoreComponent,
    LoginMessageComponent,
    ToastComponent,
    HighlightSearchTextPipe,
    ModifierPricePipe,
    RemoveSelectedItemMessageComponent,
    FormatSubmodifiersPipe,
    ModQuantityConditionPipe,
    LanguageComponent,
    SafeHtmlPipe,
    FormatTimePipe,
    PreorderTagComponent,
    StringSplitPipe,
    MenutagIconPipe,
    SkeletonPlaceholderComponent,
    StoreImagePipe,
    SettingsComponent,
    DeleteAccTncComponent,
    DeleteAccComponent,
    DeleteAccSuccessComponent,
    QrMobileDetectedComponent,
    SubmitPhoneNoComponent,
    NewsComponent,
    BannerComponent,
    NewsListComponent,
    ChannelNotFoundComponent,
    UserQrCodeComponent,
    PlatformSetCPSRPipe,
    VoidItemMessageComponent,
    OrderPaidMessageComponent,
    QrScannerComponent,
    LocationSearchComponent,
    TableNoRequiredComponent,
    AlcoholConsentMessageComponent,
    OtpToastComponent,
    MenuVariationComponent,
    SearchFilterComponent,
    SortComponent,
    EmptyFilterMessageComponent,
    SubmittingOrderComponent,
    EditPhoneNumberComponent,
    CaptureEmailComponent,
    ModGroupProcessPipe,
    ItemTotalPricePipe,
    MaintenanceComponent,
    QueueInfoComponent,
    QuitQueueMessageComponent,
    QueueRemoveCartComponent,
    ConfirmedErrorComponent,
    RegisterFailedComponent,
    TelInputCountryCodePipe,
    DashedLineComponent,
    ImageViewerComponent,
    OrderInfoComponent,
    AppliedPromoVoucherComponent,
    CalPriceDiffPipe,
    NewsDetailComponent,
    ItemUnavailableMessageComponent,
    PlatformSetCSPSRPipe,
    LanguageDescPipe,
    EvenCenterGiftPipe,
    MiniProgramInfoComponent,
    OverflowFadeDirective,
    StoreImageHeightDirective,
    PaymentStatusComponent,
    SplashCarouselComponent,
    NextAvailableTimePipe,
    IsTodayDatePipe,
    QueueImagePipe,
    PaymentMethodDescPipe,
    BottomOdaringBackComponent,
    StoreMenuTagComponent,
    MenuTagsPipe,
    FormatInnerHtmlPipe,
    IsShowMascotPipe,
    GuestInfoComponent,
    OrderCountdownComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxIntlTelInputModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PasswordModule,
    CalendarModule,
    ProgressBarModule,
    ToastModule,
    DialogModule,
    BadgeModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    RatingModule,
    TableModule,
    TagModule,
    SidebarModule,
    TranslateModule,
    ScrollPanelModule,
    SwiperModule,
    SliderModule,
    SelectButtonModule,
    AccordionModule,
    TimelineModule,
    PaginatorModule,
    FormsModule,
    InputSwitchModule,
    LottieModule.forRoot({ player: playerFactory }),
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    NgxCopyToClipboardModule,
    CountdownModule,
    BarcodeGeneratorAllModule,
    QRCodeGeneratorAllModule,
    DataMatrixGeneratorAllModule,
    SkeletonModule,
    ZXingScannerModule,
    ProgressSpinnerModule
  ],
  exports: [
    NgxIntlTelInputModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    PasswordModule,
    CalendarModule,
    ProgressBarModule,
    ToastModule,
    DialogModule,
    BadgeModule,
    DownloadAppComponent,
    FooterComponent,
    ToolbarComponent,
    SliderComponent,
    TimerComponent,
    ResetPassFormComponent,
    OtpFormComponent,
    HeaderComponent,
    LocationComponent,
    BottomNavComponent,
    AddressComponent,
    BsDropdownModule,
    RatingModule,
    TableModule,
    TagModule,
    SliderModule,
    SelectButtonModule,
    ScrollPanelModule,
    InputSwitchModule,
    SwiperModule,
    BackButtonComponent,
    AccordionModule,
    TimelineModule,
    PaginatorModule,
    AddressFormComponent,
    AddressSearchComponent,
    OrderQuickAccessSliderComponent,
    PopoularOrderSliderComponent,
    AddressListComponent,
    OrderItemComponent,
    OrderAmountComponent,
    LostNetworkConnectionComponent,
    TechnicalErrorComponent,
    LoaderComponent,
    JsonParsePipe,
    ItemCardComponent,
    PopupMessageComponent,
    StoreOdrTimeComponent,
    CartFormComponent,
    StoreItemModifierComponent,
    StoreItemContentComponent,
    StoreItemListComponent,
    StoreItemSubmodifierComponent,
    StoreItemDescriptionComponent,
    MobileFloatingCartComponent,
    EmptyDishMessageComponent,
    HomeUserMessageComponent,
    EmptyAddressMessageComponent,
    OutOfServiceMessageComponent,
    SocialShareComponent,
    ShareButtonsModule,
    ShareIconsModule,
    NgxCopyToClipboardModule,
    TooltipComponent,
    SearchNoResultComponent,
    ActivityTimelineComponent,
    ProfileNavMobileComponent,
    RemoveAllMessageComponent,
    OrderTypeIconPipe,
    DateTimeSelectionComponent,
    MobileItemCardPredefinedStoresComponent,
    MobileItemCardChannelStoresComponent,
    ActivityTimelineIconPipe,
    ItemCardVComponent,
    ItemCardHComponent,
    DineInInfoComponent,
    QrDineinSelectionComponent,
    CartSubmittedOrdersComponent,
    QrCloseMessageComponent,
    QrQuitMessageComponent,
    NearbyStoreComponent,
    LoginMessageComponent,
    ToastComponent,
    HighlightSearchTextPipe,
    RemoveSelectedItemMessageComponent,
    FormatSubmodifiersPipe,
    ModQuantityConditionPipe,
    LanguageComponent,
    SafeHtmlPipe,
    FormatTimePipe,
    PreorderTagComponent,
    StringSplitPipe,
    MenutagIconPipe,
    SkeletonPlaceholderComponent,
    SkeletonModule,
    StoreImagePipe,
    DeleteAccTncComponent,
    DeleteAccComponent,
    QrMobileDetectedComponent,
    SubmitPhoneNoComponent,
    BannerComponent,
    UserQrCodeComponent,
    PlatformSetCPSRPipe,
    VoidItemMessageComponent,
    OrderPaidMessageComponent,
    QrScannerComponent,
    ZXingScannerModule,
    TableNoRequiredComponent,
    AlcoholConsentMessageComponent,
    OtpToastComponent,
    InputTrimDirective,
    MenuVariationComponent,
    SearchFilterComponent,
    SortComponent,
    EmptyFilterMessageComponent,
    SubmittingOrderComponent,
    EditPhoneNumberComponent,
    CaptureEmailComponent,
    QueueInfoComponent,
    QuitQueueMessageComponent,
    BarcodeGeneratorAllModule,
    QRCodeGeneratorAllModule,
    DataMatrixGeneratorAllModule,
    QueueRemoveCartComponent,
    ConfirmedErrorComponent,
    RegisterFailedComponent,
    TelInputCountryCodePipe,
    DashedLineComponent,
    ImageViewerComponent,
    OrderInfoComponent,
    AppliedPromoVoucherComponent,
    CalPriceDiffPipe,
    ItemUnavailableMessageComponent,
    PlatformSetCSPSRPipe,
    SidebarModule,
    OrderTypeComponent,
    LocationSearchComponent,
    PlatformSetCSPSRPipe,
    LanguageDescPipe,
    EvenCenterGiftPipe,
    MiniProgramInfoComponent,
    OverflowFadeDirective,
    StoreImageHeightDirective,
    PaymentStatusComponent,
    MascotLoadingComponent,
    SplashCarouselComponent,
    ProgressSpinnerModule,
    NextAvailableTimePipe,
    IsTodayDatePipe,
    QueueImagePipe,
    PaymentMethodDescPipe,
    BottomOdaringBackComponent,
    StoreMenuTagComponent,
    MenuTagsPipe,
    FormatInnerHtmlPipe,
    IsShowMascotPipe,
    GuestInfoComponent,
    OrderCountdownComponent
  ],

})
export class SharedModule {}
