import { UtilsService } from '../services/utils.service';
import { QrCacheData } from '../models/local/QrCacheData';
import { StaticQrService } from '../static-qr/static-qr.service';
import { WebLinkTokenResponse } from '../models/WebLinkTokenResponse';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { QrCartService } from '../qr-cart/qr-cart.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StaticQrState } from '../static-qr/static-qr.model';
import { isPlatformServer, PlatformLocation } from '@angular/common';
import { ChannelService } from 'src/app/home/channel/channel.service';

@Injectable({
  providedIn: 'root'
})
export class StoreGuard implements CanActivate {

  qrTokenResponse : WebLinkTokenResponse;
  tableNumber : string;
  cacheQrInfo : QrCacheData;

  constructor(
    private qrCartService : QrCartService,
    private staticQrService : StaticQrService,
    private storageService : StorageService,
    private utilsService : UtilsService,
    private router : Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private channelService: ChannelService,
    private platformLocation: PlatformLocation,
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (isPlatformServer(this.platformId)) {
      return false;
    }

    let routerUrl = this.router.url;
    let currentRoute = routerUrl.split("/")[0];
    if(currentRoute){
      this.storageService.storeIsQrScan(false, state.url);
    }

    let href = this.platformLocation.href;
    let isQrTokenInLink : boolean = false;
    if (href.includes('qrToken=')){
      isQrTokenInLink = true;
    }

    this.cacheQrInfo = this.storageService.getQrInfo();
    this.qrTokenResponse = this.qrCartService.get().qrTokenResponse;
    // initially will get from static cache data
    let staticQrData = this.staticQrService.get();
    this.tableNumber = staticQrData.tableNo;
    // get query and lowercase all its keys
    let query = this.utilsService.toLowerQueryChar(route.queryParams);

    // get order type only flag
    let orderTypeOnlyData = this.staticQrService.getOrderTypeOnlyData();

    // if url have query order type and table number then assign table number with query table number
    // indicating it is static mode and no need to check
    if(query.ordertype && query.tableno){
      this.tableNumber = query.tableno;
    }

    // if still in dynamic/static qr dine in mode, cache qr info exists and cache storeId and route param storeId same
    if(!this.qrTokenResponse && !this.tableNumber && !query.qrtoken && this.cacheQrInfo && this.cacheQrInfo.storeId == +route.params["storeId"]){
      // if current time in ms less than cache expiry time then will route to the cache URL
      // else if after time then will remove the cache qr info
      let isBeforeTime = new Date().getTime() < this.cacheQrInfo.expiryTime;
      let curOrderType = this.channelService.getCurrentOrderType();
      let qrInfoQuery = this.utilsService.convertStringToObject(this.storageService.getQrInfoParamString());
      let orderTypeChanged = false;

      // if order type changed, remove the static qrinfo to avoid use back the static qr
      if (curOrderType !== qrInfoQuery.ordertype) {
        orderTypeChanged = true;
      }

      if(isBeforeTime && !orderTypeChanged){
        window.location.href = this.cacheQrInfo.qrLink;
        return false;
      }
      else{
        this.storageService.removeQrInfo();
      }
    }
    else if((!this.qrTokenResponse && query.qrtoken) || (this.qrTokenResponse && query.qrtoken && query?.qrtoken != this.qrTokenResponse?.tokenId) || isQrTokenInLink){
      this.router.navigate(["odaringqr", query.qrtoken], { replaceUrl : true });
    }
    else if(this.qrTokenResponse && !query.qrtoken){
      return of(this.router.parseUrl(state.url + `?qrToken=` + this.qrTokenResponse.tokenId));
    }
    else if(staticQrData.orderType && staticQrData.tableNo && !query.ordertype && !query.tableno
      && (!staticQrData.storeId || (staticQrData.storeId && staticQrData?.storeId == +route.params["storeId"]))){
      return this.staticQrQueryFormat(staticQrData, state);
    }
    else if(orderTypeOnlyData && orderTypeOnlyData.storeId == +route.params["storeId"] && !query.ordertype){
      return of(this.router.parseUrl(state.url + `?orderType=` + orderTypeOnlyData.orderType));
    }

    return true;
  }

  staticQrQueryFormat(staticQrData : StaticQrState, state : RouterStateSnapshot){
    if(staticQrData.orderType && staticQrData.tableNo){
      return of(this.router.parseUrl(state.url + `?orderType=` + staticQrData.orderType + `&tableNo=` + staticQrData.tableNo));
    }
    else{
      return of(this.router.parseUrl(state.url));
    }
  }

}
