import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import * as _ from 'lodash';
import { Subject, TimeoutError } from 'rxjs';
import { OrderService } from 'src/app/home/order/order.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';
import { StoreMode } from '../enums';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { OrderSourceFlag } from '../enums/OrderSourceFlag';
import { OrderTypeFlag } from '../enums/OrderTypeFlag';
import { CartModel } from '../models/CartModel';
import { CustomRequest } from '../models/CustomRequest';
import { OrderC } from '../models/OrderC';
import { OrderH } from '../models/OrderH';
import { SubmitOrderRequest } from '../models/SubmitOrderRequest';
import { ToastData } from '../models/ToastData';
import { WebLinkTokenResponse } from '../models/WebLinkTokenResponse';
import { CustomService } from '../services/custom.service';
import { TimeService } from '../services/time.service';
import { User } from '../user/user.model';
import { UserService } from '../user/user.service';
import { QrCart } from './qr-cart.model';
import { QrCartQuery } from './qr-cart.query';
import { QrCartStore } from './qr-cart.store';
import { ErrorCode } from '../enums/ErrorCode';
import { PopupMessageService } from '../services/popup-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class QrCartService {

  accessToken: string;
  isLoggedIn: boolean;
  customerId: number;
  user: User;

  channelId: number;
  channelTag: string;

  localCart: CartModel;

  orderC: OrderC = {} as OrderC;

  showSubmitPopup : boolean = false;
  globalDataVersion: string = '';

  displayQrExpiryDate$ : Subject<boolean> = new Subject<boolean>();

  constructor(
    private qrCartStore: QrCartStore,
    private timeService: TimeService,
    private customService: CustomService,
    private userService : UserService,
    private orderService : OrderService,
    private router : Router,
    private toastService : ToastService,
    private qrCartQuery: QrCartQuery,
    private popupMessageService : PopupMessageService,
    private commonService : CommonService,
    private storageService : StorageService,
    private sessionStorageService: SessionStorageService
  ) {
    this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.accessToken = userData && userData['accessToken'] ? userData['accessToken'] : '';
      this.isLoggedIn = userData? true : false;
      this.customerId = userData?.customerId ? userData.customerId : "null";
      this.user = userData? userData : "null";
    });
  }

  get() {
    return this.qrCartQuery.getValue();
  }

  updateQrTokenResponse(qrTokenResponse: WebLinkTokenResponse) {
    this.qrCartStore.update({
      qrTokenResponse: qrTokenResponse
    });

    if (!qrTokenResponse) {
      this.sessionStorageService.removeItem('qrTokenResponse');
    }
  }

  addCart(data: QrCart, linkId : number) {
    let cartData = _.cloneDeep(data) as QrCart;

    this.qrCartStore.upsert(linkId, cartData);
  }

  async recalculateCart(channelId: number, linkId : number, cartModel: CartModel, updateState: boolean = false) {
    cartModel = this.processCartModel(cartModel);

    let respData = null;
    respData = await this.reqRecalculateCart(channelId, cartModel);

    if(respData instanceof HttpErrorResponse === false){
      if (updateState) {
        this.addCart({
          id: linkId,
          cartModel: respData['body']
        }, linkId)
      }

      let paymentMethods = respData['body'].paymentMethods;
      this.storageService.setSessionStorage('pms', paymentMethods);

      return respData['body'];
    }
    else{
      //temporary solution
      if(respData.error.errorCode == "InvalidWebLinkToken_400"){
        this.commonService.removeDialogKey();

        let rr: any = [];
        let errorObj = JSON.parse(respData.error.errorData[0].errorMessage);
        rr.push(errorObj)

        this.commonService.showExpiredQrErrorData.next({
          locDesc: rr[0].LocDesc,
          storeImageUrl: rr[0].StoreImageUrl
        })
        this.router.navigate(['/invalid-qr'], { skipLocationChange: true });
      }

      return respData;
    }
  }

  async submitOrder(channelId : number, linkId: number, cartModel : CartModel, billOption : string){
    let submitOrderInfo = {} as SubmitOrderRequest;

    submitOrderInfo.channelId = channelId;
    submitOrderInfo.linkId = linkId;
    submitOrderInfo.cartTranIds = cartModel.cartTranIds;
    submitOrderInfo.orderHs = cartModel.orderHs;
    submitOrderInfo.billCombineOption = billOption;

    let resp = null;
    resp = await this.reqSubmitOrder(submitOrderInfo);

    //temporary solution
    if(resp instanceof (HttpErrorResponse)){
      if(resp.error.errorCode == ErrorCode.InvalidWebLinkToken_400){
        this.router.navigate(['/invalid-qr'], { skipLocationChange: true });
      }
      else if(resp?.error?.errorCode == ErrorCode.OrderProcessing_400){
        this.popupMessageService.show({
          icon: "oda-times",
          iconColor: "#FFFFFF",
          iconBgColor: "#EB3600",
          title: "order.submit.failed.title",
          desc: "order.submit.failed.desc",
          btn: "button.ok"
        })
      }
      return null;
    }
    else if(resp instanceof TimeoutError){
      return null;
    }
    else{
      return resp['body'];
    }

  }

  async getActivityByLinkId(linkId : number, isOrderSummary : boolean, addState : boolean = true, dataVersion?: string){
    let resp = null;
    let activityId = +this.sessionStorageService.getItem("qrActivityId");
    this.sessionStorageService.removeItem("qrActivityId");
    resp = await this.reqGetActivityByLinkId(linkId, dataVersion);

    if(!(resp instanceof HttpErrorResponse)){
      if(isOrderSummary){
        if(activityId){
          let index = resp.findIndex(orders => orders.activityId == activityId);
          if(index != -1){
            if(!this.orderService.getCurrentPage()){
              let currentPageString = JSON.stringify({orderNo : resp[index].orderNo, pageNo: index + 1})
              this.orderService.saveCurrentPage(currentPageString);
            }
          }
          else{
            this.orderService.removeCurrentPageStored();
          }
        }

        // if add state flag is true then will proceed to add to state
        if(addState){
          this.orderService.qrOrderSummaryPreNavigation(resp);
        }
      }
    }
    else{
      // need to add in error handling
      resp = [];
    }

    return resp;
  }

  private async reqRecalculateCart(channelId: number, cartModel: CartModel) {
    let newCr = {} as CustomRequest;

    if (this.isLoggedIn) {
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId
        },
        headers: {
          accessToken: this.accessToken
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest
    }
    else {
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId
        }
      } as CustomRequest
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false);
    if(respInfo.headers && respInfo.headers?.get("X-ServerDateTime")){
      this.timeService.setServerTime(respInfo.headers.get("X-ServerDateTime"));
    }

    return respInfo;
  }

  private async reqSubmitOrder(submitOrderInfo : SubmitOrderRequest){
    let newCr = {} as CustomRequest;

    newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.order.SubmitOrder,
      hostPath: environment.hostPath,
      body: submitOrderInfo,
      headers: {
        accessToken: this.accessToken
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false);
    this.timeService.setServerTime(respInfo.headers.get("X-ServerDateTime"));

    return respInfo;
  }

  private async reqGetActivityByLinkId(linkId : number, dataVersion: string){
    let newCr = {} as CustomRequest;

    if(this.isLoggedIn){
      newCr = {
        httpMethod: HttpMethod.GET,
        requestpath: environment.apis.order.GetActivityByLinkId,
        hostPath: environment.hostPath,
        queryParams: {
          LinkId: linkId
        },
        headers: {
          accessToken: this.accessToken,
          dataVersion: dataVersion,
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest
    }
    else{
      newCr = {
        httpMethod: HttpMethod.GET,
        requestpath: environment.apis.order.GetActivityByLinkId,
        hostPath: environment.hostPath,
        queryParams: {
          LinkId: linkId
        },
        headers: {
          dataVersion: dataVersion,
        },
      } as CustomRequest
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false);
    this.timeService.setServerTime(respInfo.headers.get("X-ServerDateTime"));
    this.globalDataVersion = respInfo.headers.get('Data-Version')

    return respInfo["body"];
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }

  removeCart() {
    this.qrCartStore.remove();
  }

  clearCart() {
    this.removeCart();
  }

  //after overwrite reinsert the id to the customer id that is null
  async reInsertIdToCart(cart : CartModel, customerId : number){
    let cloneCart = _.cloneDeep(cart);
    cloneCart.orderHs.map(orderH => {
      if(orderH.customerId == null){
        orderH.customerId = customerId;

        orderH.orderData.orderC.customerId = customerId;
        orderH.orderData.orderC.custName = this.user ? this.user.displayName : null;
        orderH.orderData.orderC.email = this.user? this.user.email : null;
        orderH.orderData.orderC.mobileNo = this.user? this.user.mobileNo : null;

        orderH.orderData.orderDs.map(orderD => {
          if(orderD.customerId == null){
            orderD.customerId = customerId;
          }
        })
      }
    })

    return cloneCart;
  }

  async sortIsSelectOrder(cart : CartModel){
    let cloneCart = _.cloneDeep(cart);

    cloneCart.orderHs.forEach((val, i) => {
      if(val.isSelect){
        cloneCart.orderHs.splice(i, 1);
        cloneCart.orderHs.unshift(val);
      }
    })

    return cloneCart;
  }

  async updateOrderData(currentStoreCart : OrderH, orderType: string, guestCount: number){
    currentStoreCart.orderData.guestCount = guestCount;
    let currentRefValue = this.getRefValue();
    currentStoreCart.orderData.orderC.refNo = currentRefValue ? currentRefValue : currentStoreCart.orderData.orderC.refNo;
    if(orderType == OrderTypeFlag.DineIn){
      currentStoreCart.orderData.sourceFlag = OrderSourceFlag.WebQrDineIn;
    }
    else if(orderType == OrderTypeFlag.Pickup){
      currentStoreCart.orderData.sourceFlag = OrderSourceFlag.WebQrTakeaway;
    }

    currentStoreCart = await this.updateOrderD(currentStoreCart);

    return currentStoreCart;
  }

  async updateOrderD(currentStoreCart: OrderH){
    currentStoreCart.orderData.orderDs.forEach(item => {
      item.sourceFlag = currentStoreCart.orderData.sourceFlag;
    })
    return currentStoreCart;
  }

  getQrCartSourceFlag(orderType : string){
    switch(orderType){
      case OrderTypeFlag.DineIn:{
        return OrderSourceFlag.WebQrDineIn;
      }
      case OrderTypeFlag.Pickup:{
        return OrderSourceFlag.WebQrTakeaway;
      }
      default:{
        return OrderSourceFlag.WebQrDineIn;
      }
    }
  }

  quitQrDineInCheck(){
    this.updateQrTokenResponse(null);
    this.sessionStorageService.removeItem("qrTokenResponse");

    if(this.sessionStorageService.getItem("qrOrderSummary")){
      this.sessionStorageService.removeItem("qrOrderSummary");
    }

    if(this.sessionStorageService.getItem("qrActivityId")){
      this.sessionStorageService.removeItem("qrActivityId");
    }

    if(this.sessionStorageService.getItem("qrPayment")){
      this.sessionStorageService.removeItem("qrPayment");
    }

    if(this.sessionStorageService.getItem("forceCheckout")){
      this.sessionStorageService.removeItem("forceCheckout");
    }
  }

  orderSummaryQuitCheck(){
    if(this.sessionStorageService.getItem("qrTokenResponse")){
      this.qrCartStore.update({qrTokenResponse : null});
      this.sessionStorageService.removeItem("qrTokenResponse");
    }

    if(this.sessionStorageService.getItem("qrOrderSummary")){
      this.sessionStorageService.removeItem("qrOrderSummary");
    }

    if(this.sessionStorageService.getItem("qrActivityId")){
      this.sessionStorageService.removeItem("qrActivityId");
    }
  }

  savedQrOrderType(orderType : string){
    this.sessionStorageService.setItem("qrOrderType", orderType);
  }

  removeQrOrderType(){
    this.sessionStorageService.removeItem("qrOrderType");
  }

  setShowSubmitPopup(toShow : boolean){
    this.showSubmitPopup = toShow;
  }

  getShowSubmitPopup(){
    return this.showSubmitPopup;
  }

  //#region qr ref value
  removeRefValue(){
    this.sessionStorageService.removeItem("refValue");
  }

  setRefValue(refInput : string){
    this.sessionStorageService.setItem("refValue", refInput);
  }

  getRefValue(){
    if(this.sessionStorageService.getItem("refValue")){
      return this.sessionStorageService.getItem("refValue");
    }
    else{
      return null;
    }
  }
  //#endregion

  showEditPhoneSuccessToast(isMobileView : boolean){
    let toastData = {} as ToastData;
    toastData.message = "order.edit.phone.number.desc";
    toastData.icon = "oda-check-alt";
    toastData.iconColor = "#8CD600";
    toastData.bottomValue = isMobileView ? '64.5px' : '78px';
    this.toastService.show(toastData);
  }

  processCartModel(cartModel: CartModel) {
    if(cartModel && cartModel.orderHs && cartModel.orderHs?.length > 0) {
      cartModel.orderHs.forEach((orderH: OrderH) => {
        orderH.orderData.orderVs = [];
        orderH.orderData.menuRowVersion = orderH.orderData.menuRowVersion? orderH.orderData.menuRowVersion: "0";
      });
    }

    return cartModel;
  }
}
